const mediaSizes = {
  phone: '37.5em',
  tabLand: '75em',
};

const devices = {
  phone: `(max-width: ${mediaSizes.phone})`,
  tabLand: `(max-width: ${mediaSizes.tabLand})`,
};

export default devices;
