import React from 'react';
import Dropdown, { DropdownProps } from '../Dropdown/Dropdown';
import darkDropdownStyles from './darkDropdown.style';
import { DropdownIndicator } from './components';

const DarkDropdown: React.FC<DropdownProps> = (props) => {
  return (
    <Dropdown
      {...props}
      styles={darkDropdownStyles}
      components={{ ...props.components, DropdownIndicator }}
    />
  );
};

export default DarkDropdown;
