import { useState, useEffect } from 'react';

interface WindowDimensionsI {
  windowWidth: number;
  windowHeight: number;
}

const getWindowDimensions = (wind: Window) => {
  const { innerWidth: windowWidth, innerHeight: windowHeight } = wind;
  return {
    windowWidth,
    windowHeight,
  };
};

const useWindowDimensions = (): WindowDimensionsI => {
  const [windowDimensions, setWindowDimensions] = useState<WindowDimensionsI>({
    windowWidth: 0,
    windowHeight: 0,
  });

  useEffect(() => {
    setWindowDimensions(getWindowDimensions(window));
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions(window));
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

export default useWindowDimensions;
