import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { colors } from '../variables';
import { LabelTypes } from './LabelTypes';

export const LabelTypeStyle = ({
  labelType,
}: {
  labelType: LabelTypes;
}): FlattenSimpleInterpolation => {
  switch (labelType) {
    case 'critical':
      return css`
        border-radius: 10px;
        background: ${colors.critical200};
        color: ${colors.primaryRed};
        border-color: ${colors.critical300};
      `;
    case 'warning':
      return css`
        border-radius: 10px;
        background: ${colors.warning200};
        color: ${colors.main900};
        border-color: ${colors.warning700};
      `;
    case 'success':
      return css`
        border-radius: 10px;
        background: ${colors.success200};
        color: ${colors.primaryGreen};
        border-color: ${colors.success300};
      `;
    case 'info':
      return css`
        border-radius: 10px;
        background: ${colors.info200};
        color: ${colors.info700};
        border-color: ${colors.info300};
      `;
    case 'unconfirmed':
      return css`
        border-radius: 2px;
        color: ${colors.main500};
        border-color: ${colors.main500};
      `;
    case 'confirmed':
      return css`
        border-radius: 2px;
        background: rgba(76, 194, 112, 0.5);
        border-color: ${colors.green50};
      `;
    case 'inTransit':
      return css`
        border-radius: 2px;
        background: ${colors.success200};
        border-color: ${colors.primaryGreen};
      `;
    case 'inWaitingRoom':
      return css`
        border-radius: 2px;
        background: rgba(255, 132, 115, 0.5);
        border-color: ${colors.orange50};
      `;
    case 'admitForSurgery':
      return css`
        border-radius: 2px;
        background: rgba(229, 185, 50, 0.5);
        border-color: ${colors.yellow50};
      `;
    case 'inConsultation':
      return css`
        border-radius: 2px;
        background: ${colors.primary100};
        border-color: ${colors.primary700};
      `;
    case 'inProcedure':
      return css`
        border-radius: 2px;
        background: rgba(79, 184, 255, 0.2);
        border-color: ${colors.tertiaryMediumBlue};
      `;
    case 'inHospital':
      return css`
        border-radius: 2px;
        background: rgba(38, 191, 199, 0.5);
        border-color: ${colors.secondaryGreen};
      `;
    case 'inDischarge':
      return css`
        border-radius: 2px;
        background: rgba(60, 0, 233, 0.2);
        border-color: ${colors.purple70};
      `;
    case 'awaitingCollect':
      return css`
        border-radius: 2px;
        background: rgba(202, 40, 129, 0.2);
        border-color: ${colors.primaryPink};
      `;
    case 'departed':
      return css`
        border-radius: 2px;
        background: ${colors.main200};
        border-color: ${colors.main500};
      `;
    case 'dollar':
      return css`
        border-radius: 2px;
        background: rgba(250, 77, 86, 0.5);
        color: ${colors.primaryRed};
        border-color: ${colors.red50};
        width: auto;
        margin: 0 4px;
      `;
    default:
      return css``;
  }
};

export const BaseStyle = (): FlattenSimpleInterpolation => css`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 0.8rem;
  text-overflow: ellipsis;
  font-weight: normal;
  font-family: 'Roboto';
  line-height: 2rem;
  text-align: center;
  vertical-align: middle;
  font-size: 1.2rem;
  width: 11.2rem;
  height: 2rem;
  margin: auto;
  border: 1px solid ${colors.main700};
  &,
  &:hover,
  &:active,
  &:focus {
    outline: none;
  }
`;

export const StyleLabel = styled.span<{ labelType: LabelTypes }>`
  ${BaseStyle}
  ${LabelTypeStyle}
`;

export const Container = styled.div`
  width: max-content;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  gap: 0.3rem;
  align-items: center;
`;
