import styled from 'styled-components';
import { GroupTypeBase, StylesConfig } from 'react-select';
import { colors } from '../variables';
import { DropdownOptionType } from '../Dropdown';

const getOptionBGColor = (isFocused: boolean, isSelected: boolean) => {
  if (isSelected) {
    return colors.primary300;
  }
  if (isFocused) {
    return colors.primary200;
  }
  return 'inherit';
};

export const PHMenuStyles:
  | Partial<
      StylesConfig<
        DropdownOptionType,
        boolean,
        GroupTypeBase<DropdownOptionType>
      >
    >
  | undefined = {
  control: (provided) => ({
    ...provided,
    padding: 0,
    width: '32px',
    minHeight: '32px',
    cursor: 'pointer',
    borderRadius: '2px',
    border: 'none',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '&:focus-within': {
      borderColor: 'none',
    },
  }),
  menuList: (provided) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 400,
  }),
  singleValue: (provided) => ({
    ...provided,
    margin: '0',
  }),
  option: (provided, { selectProps, isDisabled, isSelected, isFocused }) => {
    return {
      ...provided,
      background: getOptionBGColor(isFocused, isSelected),
      color: isDisabled ? colors.main400 : colors.main900,
      fontWeight: isSelected ? '500' : '400',
      svg: {
        color: selectProps.singleValueProps ? colors.main500 : 'unset',
      },
      '&:hover': {
        background: colors.primary200,
      },
      '&:hover svg': {
        color: selectProps.singleValueProps ? colors.main900 : 'unset',
      },
    };
  },
};

export const PHSingleValueContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  margin: auto;
  min-width: 32px;
  height: 32px;
  border-radius: 4px;
  &:hover {
    background-color: rgba(238, 241, 244, 0.2);
  }
`;
