import styled from 'styled-components';
import MuiAccordion from '@mui/material/Accordion';

const Accordion = styled(MuiAccordion)`
  & > * {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2.2rem;
  }
`;

export default Accordion;
