import React, { ReactNode } from 'react';
import styled from 'styled-components';
import DischargeDeleteIcon from './components/DischargeDeleteIcon';
import ConnectedFields from './components/ConnectedFields';

const Container = styled.div`
  display: flex;
  padding: 0.5rem 0;
`;

export interface DischargeWrapperProps {
  onDelete?: () => void;
  children?: ReactNode;
  deletable?: boolean;
  className?: string;
}

const DischargeWrapper: React.FC<DischargeWrapperProps> = ({
  children,
  onDelete,
  deletable,
  className,
}) => {
  return (
    <Container className={className}>
      <ConnectedFields>{children}</ConnectedFields>
      {deletable && <DischargeDeleteIcon onDelete={onDelete} />}
    </Container>
  );
};

export default DischargeWrapper;
