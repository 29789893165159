import { DropdownOptionType } from '../../../Dropdown';
import { PHAppMenuOptionsI } from '../types';

export const constructAppOptions = (
  apps: PHAppMenuOptionsI[]
): DropdownOptionType[] => {
  return apps.map((app) => ({
    label: app.label,
    value: app.url,
    iconType: app.appName,
  }));
};
