import styled from 'styled-components';
import { colors } from '../variables';

export const ProgressBarContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const ProgressBarTotal = styled.div`
  flex-grow: 1;
  background-color: ${colors.primaryGray};
  display: inline-block;
  border-radius: 0.25rem;
`;

interface ProgressBarCompletedProps {
  completed: number | null;
  total: number | null;
}

export const ProgressBarCompleted = styled.div<ProgressBarCompletedProps>`
  height: 100%;
  background-color: ${colors.main};
  border-radius: ${(props) =>
    props.completed && props.total && props.completed >= props.total
      ? '0.25rem'
      : '0.25rem 0 0 0.25rem'};
  transition: width 0.6s ease;
`;
