import styled from 'styled-components';
import { colors } from '../variables';

export default styled.textarea`
  display: block;
  border-radius: 2px;
  padding: 0.8rem;
  border: 0.1rem solid ${colors.primaryDarkGray};
  font-size: inherit;
  font-family: inherit;

  &:focus,
  &:active {
    box-shadow: 0 0 0 1px ${colors.main};
  }

  &:hover {
    border: 0.1rem solid ${colors.main};
  }

  &,
  &:focus,
  &:active {
    outline: none;
  }
`;
