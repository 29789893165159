import React, { ReactNode } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: grid;
  grid-template-rows: min-content min-content;
`;

const Label = styled.label`
  display: flex;
  align-items: flex-end;
  font-family: 'Roboto';
`;

export interface FieldWrapperProps {
  label?: string;
  children: ReactNode;
  className?: string;
}

const FieldWrapper: React.FC<FieldWrapperProps> = ({
  children,
  label,
  className,
}) => {
  return (
    <Container className={className}>
      {label ? <Label>{label}</Label> : <br />}
      {children}
    </Container>
  );
};

export default FieldWrapper;
