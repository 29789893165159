import styled, { css } from 'styled-components';

const makeDirectChildStyledInherited = () => css`
  & > * {
    font-style: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
  }
`;

export const TypoH1 = styled.span`
  font-style: normal;
  font-family: Roboto;
  font-weight: 300;
  font-size: 96px;
  line-height: 112px;
  letter-spacing: -0.2px;
  ${makeDirectChildStyledInherited}
`;

export const TypoH2 = styled.span`
  font-style: normal;
  font-family: Roboto;
  font-weight: bold;
  font-size: 64px;
  line-height: 40px;
  ${makeDirectChildStyledInherited}
`;

export const TypoH3 = styled.span`
  font-style: normal;
  font-family: Roboto;
  font-weight: 500;
  font-size: 40px;
  line-height: 32px;
  ${makeDirectChildStyledInherited}
`;

export const TypoH4 = styled.span`
  font-style: normal;
  font-family: Roboto;
  font-weight: normal;
  font-size: 34px;
  line-height: 32px;
  ${makeDirectChildStyledInherited}
`;

export const TypoH5 = styled.span`
  font-style: normal;
  font-family: Roboto;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  ${makeDirectChildStyledInherited}
`;

export const TypoH6 = styled.span`
  font-style: normal;
  font-family: Roboto;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  ${makeDirectChildStyledInherited}
`;

export const TypoSubtitle1 = styled.span`
  font-style: normal;
  font-family: Roboto;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  ${makeDirectChildStyledInherited}
`;

export const TypoSubtitle2 = styled.span`
  font-style: normal;
  font-family: Roboto;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  ${makeDirectChildStyledInherited}
`;

export const TypoBody1 = styled.span`
  font-style: normal;
  font-family: Roboto;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  ${makeDirectChildStyledInherited}
`;

export const TypoBody2 = styled.span`
  font-style: normal;
  font-family: Roboto;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  ${makeDirectChildStyledInherited}
`;

export const TypoBody3 = styled.span`
  font-style: normal;
  font-family: Roboto;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-decoration-line: underline;
  ${makeDirectChildStyledInherited}
`;

export const TypoBody4 = styled.span`
  font-style: italic;
  font-family: Roboto;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  ${makeDirectChildStyledInherited}
`;

export const TypoButton1 = styled.span`
  font-style: normal;
  font-family: Roboto;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  ${makeDirectChildStyledInherited}
`;

export const TypoCaption = styled.span`
  font-style: normal;
  font-family: Roboto;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  ${makeDirectChildStyledInherited}
`;

export const TypoOverline1 = styled.span`
  font-style: normal;
  font-family: Roboto;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  ${makeDirectChildStyledInherited}
`;

export const TypoOverline2 = styled.span`
  font-style: normal;
  font-family: Roboto;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  ${makeDirectChildStyledInherited}
`;
