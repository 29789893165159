import React from 'react';

const WhiteboardLogo: React.FC<React.SVGProps<SVGSVGElement>> = ({
  height = '2.4rem',
  width = '2.4rem',
  ...props
}): React.ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 94 94"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>icon-whiteboard</title>
    <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Solution-PetGenus"
        transform="translate(-1060.000000, -2141.000000)"
      >
        <g id="Group-7-Copy-3" transform="translate(939.000000, 2109.000000)">
          <g id="icon-whiteboard" transform="translate(121.000000, 32.000000)">
            <rect
              id="Rectangle"
              fill="#FFF2F2"
              x="0"
              y="0"
              width="94"
              height="94"
              rx="47"
            ></rect>
            <g
              id="reminder--medical"
              transform="translate(20.000000, 21.000000)"
            >
              <path
                d="M45.5625,20.25 L45.5625,23.625 L47.25,23.625 L47.25,30.375 C47.2499999,33.1709415 44.9834415,35.4374998 42.1875,35.4374998 C39.3915585,35.4374998 37.1250001,33.1709415 37.125,30.375 L37.125,23.625 L38.8125,23.625 L38.8125,20.25 L33.75,20.25 L33.75,30.375 C33.7554833,34.382448 36.5746027,37.8350941 40.5,38.6418938 L40.5,40.5 C40.5,44.2279221 37.4779221,47.25 33.75,47.25 C30.0220779,47.25 27,44.2279221 27,40.5 L27,36.8139938 C29.32461,35.99212 30.7190341,33.615057 30.3020927,31.1849442 C29.8851513,28.7548314 27.7781213,26.978523 25.3125,26.978523 C22.8468787,26.978523 20.7398487,28.7548314 20.3229073,31.1849442 C19.9059659,33.615057 21.30039,35.99212 23.625,36.8139938 L23.625,40.5 C23.625,46.0918831 28.1581169,50.625 33.75,50.625 C39.3418831,50.625 43.875,46.0918831 43.875,40.5 L43.875,38.6418938 C47.8003973,37.8350941 50.6195167,34.382448 50.625,30.375 L50.625,20.25 L45.5625,20.25 Z M25.3125,30.375 C26.2444805,30.375 27,31.1305195 27,32.0625 C27,32.9944805 26.2444805,33.75 25.3125,33.75 C24.3805195,33.75 23.625,32.9944805 23.625,32.0625 C23.6258373,31.1308665 24.3808665,30.3758373 25.3125,30.375 L25.3125,30.375 Z"
                id="Shape"
                fill="#FF5B5B"
                fillRule="nonzero"
              ></path>
              <path
                d="M43.875,6.75 L37.125,6.75 L37.125,3.375 L33.75,3.375 L33.75,6.75 L20.25,6.75 L20.25,3.375 L16.875,3.375 L16.875,6.75 L10.125,6.75 C8.26103897,6.75 6.75,8.26103897 6.75,10.125 L6.75,43.875 C6.75,45.738961 8.26103897,47.25 10.125,47.25 L16.875,47.25 L16.875,43.875 L10.125,43.875 L10.125,10.125 L16.875,10.125 L16.875,13.5 L20.25,13.5 L20.25,10.125 L33.75,10.125 L33.75,13.5 L37.125,13.5 L37.125,10.125 L43.875,10.125 L43.875,16.875 L47.25,16.875 L47.25,10.125 C47.25,8.26103897 45.738961,6.75 43.875,6.75 Z"
                id="Path"
                fill="#FF5B5B"
                fillRule="nonzero"
              ></path>
              <rect
                id="_Transparent_Rectangle_"
                x="0"
                y="0"
                width="54"
                height="54"
              ></rect>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default WhiteboardLogo;
