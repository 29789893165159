// eslint-disable-next-line import/prefer-default-export, no-shadow
export enum APP_KEYS {
  AUTH = 'AUTH',
  ADMIN = 'ADMIN',
  CONFIG = 'CONFIG',
  PROFILE = 'PROFILE',
  ROUNDS = 'ROUNDS',
  WHITEBOARD = 'WHITEBOARD',
  OPS_GENUS = 'OPS_GENUS',
  VET_GENUS = 'VET_GENUS',
  RESEARCH_GENUS = 'RESEARCH_GENUS',
  PET_GENUS = 'PET_GENUS',
}

export const MODAL_Z_INDEX = 400;
export const SPINNER_Z_INDEX = 500;
