import { DropdownOptionType } from '../Dropdown';

const makeLabel = (label: string) =>
  label.charAt(0).toUpperCase() + label.slice(1);

export const getOption = (
  item?: string | number
): { label: string; value: string | number | null } => {
  if (!item) return { label: '', value: null };

  return {
    label: makeLabel(item.toString()),
    value: item,
  };
};

export const getOptions = (
  items?: (string | number)[]
): DropdownOptionType[] => {
  if (!items) return [];
  return items.map((item) => getOption(item));
};
