import React, { useMemo, useState } from 'react';
import { DatePicker } from '../DatePicker';
import ENG from '../translation/translationEn.json';
import FR from '../translation/translationFr.json';
import { TableHead, TableBody, TableTh, DeletableKeyPair } from './components';
import {
  MedicationTable,
  AddMedicationIcon,
  InputTd,
  MedicationsInput,
  MedicationTd,
  DatePickerWrapper,
  AddMedicationContainer,
  MedicationTimePicker,
} from './Medications.style';
import { MedicationNameModal } from './components';

type MedicationType = 'decisioning' | 'admissions';

export interface MedicationI {
  name: string;
  date_last_taken: number | null; // date picker
  time_last_taken: string | null; // dropdown between 00.00 to next day
  strength?: string | null;
  doses?: string | null;
  frequency?: string | null;
}

export interface MedicationsProps {
  medications: MedicationI[];
  updateMedications: (medications: MedicationI[]) => void;
  medicationsList: { id: number; name: string }[];
  medicationType?: MedicationType;
  lang?: 'en' | 'fr'; // for multi languages apps.
}

const Medications: React.FC<MedicationsProps> = ({
  medications,
  updateMedications,
  medicationsList,
  medicationType = 'decisioning',
  lang = 'en',
}) => {
  const translation = lang === 'fr' ? FR : ENG;
  const [medicationIndex, setMedicationIndex] = useState<number>();

  const medicationChangeHandler = (
    key: string,
    value: string | number,
    index: number
  ) => {
    const copyMedications = [...medications];
    copyMedications[index] = {
      ...copyMedications[index],
      [key]: value,
    };
    updateMedications(copyMedications);
  };

  const inputChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ): void => {
    medicationChangeHandler(e.target.name, e.target.value, index);
  };

  const addNewMedication = () => {
    const copyMedications = [...medications];
    copyMedications.push({
      name: '',
      strength: null,
      doses: null,
      frequency: null,
      date_last_taken: null,
      time_last_taken: null,
    });
    updateMedications(copyMedications);
  };

  const deleteMedication = (index: number) => {
    const copyMedications = [...medications];
    copyMedications.splice(index, 1);

    updateMedications(copyMedications);
  };

  const medicationsOptions = useMemo(() => {
    return medicationsList.map((m) => m.name);
  }, [medicationsList]);

  const renderRows = () =>
    medications.map((m, index) => (
      <tr key={`m${index}`}>
        <MedicationTd>
          <div>
            <DeletableKeyPair onDelete={() => deleteMedication(index)}>
              <MedicationsInput
                value={m.name}
                onFocus={() => setMedicationIndex(index)}
                onBlur={() => setMedicationIndex(undefined)}
                readOnly
                inputMode="none"
              />
            </DeletableKeyPair>
          </div>
        </MedicationTd>
        {medicationType === 'decisioning' ? (
          <>
            <InputTd>
              <MedicationsInput
                onBlur={(e) => inputChangeHandler(e, index)}
                defaultValue={m.strength || ''}
                name="strength"
              />
            </InputTd>
            <InputTd>
              <MedicationsInput
                onBlur={(e) => inputChangeHandler(e, index)}
                defaultValue={m.doses || ''}
                name="doses"
              />
            </InputTd>
            <InputTd>
              <MedicationsInput
                onBlur={(e) => inputChangeHandler(e, index)}
                defaultValue={m.frequency || ''}
                name="frequency"
              />
            </InputTd>
          </>
        ) : null}
        <MedicationTd>
          <DatePickerWrapper>
            <DatePicker
              selected={m.date_last_taken ? new Date(m.date_last_taken) : null}
              onChange={(date) => {
                if (date) {
                  const newDate = date as Date;

                  medicationChangeHandler(
                    'date_last_taken',
                    newDate.getTime(),
                    index
                  );
                }
              }}
            />
          </DatePickerWrapper>
        </MedicationTd>
        <MedicationTd>
          <MedicationTimePicker
            selectedTime={m.time_last_taken}
            timeChangeHandler={(time) =>
              medicationChangeHandler('time_last_taken', time, index)
            }
          />
        </MedicationTd>
      </tr>
    ));

  return (
    <>
      <MedicationNameModal
        medicationIndex={medicationIndex}
        nameSelectHandler={(newMedName, i) =>
          medicationChangeHandler('name', newMedName, i)
        }
        medicationNamesList={medicationsOptions}
        translation={translation}
      />
      <MedicationTable>
        <TableHead>
          <tr>
            <TableTh>{translation.medications.name}</TableTh>
            {medicationType === 'decisioning' ? (
              <>
                <TableTh>{translation.medications.strength}</TableTh>
                <TableTh>{translation.medications.doses}</TableTh>
                <TableTh>{translation.medications.freq}</TableTh>
              </>
            ) : null}
            <TableTh>{translation.medications.dateLast}</TableTh>
            <TableTh>{translation.medications.timeLast}</TableTh>
          </tr>
        </TableHead>
        <TableBody>
          {renderRows()}
          <tr>
            <MedicationTd colSpan={6}>
              <AddMedicationContainer onClick={addNewMedication}>
                <AddMedicationIcon />
                <p>{translation.medications.addNew}</p>
              </AddMedicationContainer>
            </MedicationTd>
          </tr>
        </TableBody>
      </MedicationTable>
    </>
  );
};

export default Medications;
