import React, { useEffect, useState } from 'react';
import { getOptions, getOption } from '../../../utils';
import { Modal } from '../../../Modal';
import { Dropdown } from '../../../Dropdown';
import { Container, AddBtn } from './MedicationNameModal.style';

interface MedicationNameModalProps {
  medicationIndex?: number;
  nameSelectHandler: (name: string, index: number) => void;
  medicationNamesList: string[];
  translation: { [key: string]: any };
}

const MedicationNameModal: React.FC<MedicationNameModalProps> = ({
  medicationIndex,
  nameSelectHandler,
  medicationNamesList,
  translation,
}) => {
  const [displayModal, setDisplayModal] = useState(false);
  const [medName, setMedName] = useState<string>();
  const [medIndex, setMedIndex] = useState<number>();

  useEffect(() => {
    if (typeof medicationIndex === 'number') {
      setDisplayModal(true);
      setMedIndex(medicationIndex);
    }
    setMedName('');
  }, [medicationIndex]);

  return (
    <Modal show={displayModal} backgroundClick={() => setDisplayModal(false)}>
      <Container>
        <Dropdown
          options={getOptions(medicationNamesList)}
          isSearchable
          oneSelectHandler={(item) => setMedName(item.value as string)}
          onInputChange={(newName) => {
            if (newName) {
              setMedName(newName);
            }
          }}
          value={medName ? getOption(medName) : undefined}
          placeholder={translation.medications.selectMedicationName}
          styles={{}}
        />
        <AddBtn
          onClick={() => {
            if (medName) {
              nameSelectHandler(medName, medIndex as number);
            }
            setDisplayModal(false);
          }}
        >
          {translation.medications.addName}
        </AddBtn>
      </Container>
    </Modal>
  );
};

export default MedicationNameModal;
