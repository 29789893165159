import styled from 'styled-components';
import { colors } from '../variables';

export const Table = styled.table`
  border: none;
  margin: 0 0 !important;
  min-width: 100%;
  border-spacing: 0;
`;

export const TableHead = styled.thead`
  background: ${colors.white};
  box-shadow: 0px 1px 0px ${colors.main400};
  border-bottom: 1px solid ${colors.main400};
`;

export const TableBody = styled.tbody`
  & > tr {
    border-bottom: 1px solid ${colors.main400};
    &:hover {
      background: ${colors.primary200};
      color: ${colors.main900};
    }
    &:active {
      background: ${colors.primary300};
      color: ${colors.main900};
    }
  }
  tr:last-child {
    border: none;
  }
`;

export const TableTd = styled.td`
  border: none;
  border-collapse: collapse;
  padding-top: 0.8rem;
  padding-right: 0rem;
  padding-bottom: 0.8rem;
  padding-left: 1.6rem;
  white-space: nowrap;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: ${colors.main900};
`;

export const TableTh = styled.th`
  border: none;
  border-collapse: collapse;
  padding-top: 0.8rem;
  padding-right: 0rem;
  padding-bottom: 0.8rem;
  padding-left: 1.6rem;
  white-space: nowrap;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: ${colors.main500};
  &:active {
    color: ${colors.main900};
  }
`;

export const LeftTd = styled(TableTd)`
  text-align: right;
`;

export const LeftTh = styled(TableTh)`
  text-align: right;
`;
