import React from 'react';
import {
  Container,
  KeyPairDeleteIcon,
  ChildrenContainer,
} from './DeletableKeyPair.style';

interface DeletableKeyPairProps {
  onDelete: () => void;
  children: React.ReactNode;
}

const DeletableKeyPair: React.FC<DeletableKeyPairProps> = ({
  onDelete,
  children,
}) => {
  return (
    <Container>
      <KeyPairDeleteIcon onClick={onDelete} />
      <ChildrenContainer>{children}</ChildrenContainer>
    </Container>
  );
};

export default DeletableKeyPair;
