import { TiPlusOutline as PlusIcon } from 'react-icons/ti';
import styled from 'styled-components';
import { Input } from '../Input';
import { Table, TableTd } from './components';
import { colors, devices } from '../variables';
import { TimePicker } from '../TimePicker';

export const MedicationTd = styled(TableTd)`
  padding: 0.5rem 0.2rem;
`;

export const InputTd = styled(MedicationTd)``;

export const MedicationsInput = styled(Input)`
  padding: 0;
  max-width: 10rem;
`;

export const DatePickerWrapper = styled.div`
  .react-datepicker {
    font-size: inherit;
  }
  .react-datepicker__header {
    padding-top: 0.8em;
  }
  .react-datepicker__month {
    margin: 0.4em 1em;
  }
  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 1.9em;
    line-height: 1.9em;
    margin: 0.166em;
  }
  .react-datepicker__current-month {
    font-size: inherit;
  }
  .react-datepicker__navigation {
    top: 1em;
    line-height: 1.7em;
    border: 0.45em solid transparent;
  }
  .react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1em;
  }
  .react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1em;
  }

  /* .react-datepicker-wrapper,
  .react-datepicker__input-container, */
  .react-datepicker__input-container input {
    font-size: inherit;
    padding: 0;
    border-radius: 2px;
    @media ${devices.phone} {
      width: 10rem;
    }
  }
`;

export const MedicationTable = styled(Table)`
  width: 100%;
`;

export const AddMedicationContainer = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  font-size: 130%;
  color: ${colors.main};
  cursor: pointer;

  & > p {
    margin: 0 0;
  }
`;

export const MedicationTimePicker = styled(TimePicker)`
  font-size: inherit;
  padding: 0;
`;

export const AddMedicationIcon = styled(PlusIcon)`
  cursor: pointer;
  border: 1px solid ${colors.main};
`;
