import styled from 'styled-components';
import MuiAccordion from '@mui/material/Accordion';
import {
  AccordionSummary as AccordionSummaryMUI,
  AccordionDetails as AccordionDetailsMUI,
} from '@mui/material';

const TableAccordion = styled(MuiAccordion)`
  &.MuiAccordion-root {
    & > * {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 2.2rem;
      min-height: 0rem !important;
    }
  }

  &.MuiAccordion-root .MuiAccordion-region {
    & > div {
      padding: 0rem auto;
    }
  }
`;

const TableAccordionSummary = styled(AccordionSummaryMUI)`
  &.MuiAccordionSummary-root {
    padding: 0rem;
    padding-left: 6rem;
  }

  &.MuiAccordionSummary-root .MuiAccordionSummary-expandIconWrapper {
    position: absolute;
    left: 2rem;
  }

  &.MuiAccordionSummary-root .MuiAccordionSummary-content {
    margin: 0rem;
    padding: 0rem;
  }
`;

const TableAccordionDetails = styled(AccordionDetailsMUI)`
  &.MuiAccordionDetails-root {
    padding: 0rem;
    padding-left: 6rem;
  }
`;

export { TableAccordion, TableAccordionSummary, TableAccordionDetails };
