import React from 'react';
import styled from 'styled-components';
import sizes from '../../variables/sizes';

const Container = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: ${sizes.header_items_gap};
`;

interface PHeaderOptionsLProps {
  children: React.ReactNode;
}

const PHeaderOptionsL: React.FC<PHeaderOptionsLProps> = ({ children }) => {
  return <Container>{children}</Container>;
};

export default PHeaderOptionsL;
