import styled from 'styled-components';
import { TypoH2, TypoH3, TypoH6 } from '../Typography';
import { colors } from '../variables';

export const Page403Container = styled.div`
  display: grid;
  grid-template-rows: max-content 1fr;
`;

export const Page403HeaderContainer = styled.div`
  padding-top: 50px;
  padding-left: 40px;
`;

export const Page403ContentContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 62px;
`;

export const PageHeading = styled(TypoH2)`
  color: ${colors.main};
  padding-top: 49px;
`;

export const P403TypoH3 = styled(TypoH3)`
  color: ${colors.main900};
  padding-top: 32px;
`;

export const P403TypoH6 = styled(TypoH6)`
  color: ${colors.main500};
  padding-top: 24px;
`;
