import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import {
  BsListOl,
  BsXCircleFill,
  BsDash,
  BsPlus,
  BsCurrencyDollar,
  BsFillEyeFill,
  BsFillEyeSlashFill,
} from 'react-icons/bs';
import { MdError } from 'react-icons/md';
import { FormType, InputProps } from './FormInputTypes';
import { colors } from '../variables';

const AsteriskColor = (error?: boolean, disabled?: boolean) => {
  if (disabled) {
    return colors.main400;
  }
  switch (error) {
    case true:
      return colors.critical700;
    default:
      return colors.primary700;
  }
};

const makeDisabled = () => css`
  & * {
    color: ${colors.main400};
    background-color: ${colors.main200};
    &:hover button {
      color: ${colors.main400};
      background-color: ${colors.main200};
    }
    &:focus-within button {
      color: ${colors.main400};
      background-color: ${colors.main200};
    }
    &:active {
      color: ${colors.main400};
      background-color: ${colors.main200};
    }
  }
`;

export const LabelTypeStyle = ({
  error = false,
  disabled,
}: {
  error?: boolean;
  disabled?: boolean;
}): FlattenSimpleInterpolation => {
  if (disabled) {
    return css`
      color: ${colors.main400};
    `;
  }
  if (error === false) {
    return css`
      color: ${colors.main500};
    `;
  }
  return css`
    color: ${colors.critical700};
  `;
};

export const TextInputTypeStyle = ({
  error = false,
  required = false,
  disabled = false,
}: {
  error?: boolean;
  required?: boolean;
  disabled?: boolean;
}): FlattenSimpleInterpolation => {
  if (disabled) {
    return css`
      background-color: ${colors.main200};
      border: 1px solid ${colors.main400};
      outline: none;
      &::placeholder {
        color: ${colors.main500};
        font-size: 1.4rem;
        font-style: italic;
      }
    `;
  }
  if (error === false && required === false) {
    return css`
      background-color: ${colors.white};
      border: 1px solid ${colors.main400};
      outline: none;
      &:hover {
        border: 1px solid ${colors.main500};
      }
      &:focus-within {
        border: 1px solid ${colors.primary700};
      }
      &::placeholder {
        color: ${colors.main500};
        font-size: 1.4rem;
        font-style: italic;
      }
      &::selection {
        background: ${colors.primary400};
      }
    `;
  }
  if (error === false && required) {
    return css`
      border: 1px solid ${colors.main400};
      background: ${colors.primary300};
      outline: none;
      &:hover {
        border: 1px solid ${colors.main500};
      }
      &:focus-within {
        border: 1px solid ${colors.primary700};
      }
      &::placeholder {
        color: ${colors.main500};
        font-size: 1.4rem;
        font-style: italic;
      }
      &::selection {
        background: ${colors.warning300};
      }
    `;
  }
  if (error && required === false) {
    return css`
      border: 1px solid ${colors.critical700};
      outline: none;
      &::placeholder {
        color: ${colors.main500};
        font-size: 1.4rem;
        font-style: italic;
      }
      &::selection {
        background: ${colors.primary400};
      }
    `;
  }
  return css`
    outline: none;
    color: ${colors.main900};
    background: ${colors.critical200};
    border: 1px solid ${colors.critical700};
    &::placeholder {
      color: ${colors.main500};
      font-size: 1.4rem;
      font-style: italic;
    }
    &::selection {
      background: ${required && !error ? colors.warning300 : colors.primary400};
    }
  `;
};

export const BaseStyle = (): FlattenSimpleInterpolation =>
  css`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    color: ${colors.main900};
    background-color: transparent;
    font-size: 1.6rem;
    line-height: 2.6rem;
    padding: 0.5rem 1.2rem;
    border-radius: 2px;
  `;

export const TextContainer = styled.div<{
  inputType?: FormType;
  error?: boolean;
  required?: boolean;
  disabled?: boolean;
}>`
  display: flex;
  justify-content: ${(p) =>
    p.inputType === 'quantity' ? 'space-between' : 'unset'};
  ${TextInputTypeStyle};
  padding-right: 1.2rem;
  border-radius: 2px;
  ${(p) => (p.disabled ? makeDisabled : null)};
`;

export const NewArea = styled.input<{
  formType?: FormType;
  required?: boolean;
  error?: boolean;
}>`
  ${BaseStyle};
  line-height: 2.6rem;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  width: 100%;
  margin: auto;
  outline: none;
  &::placeholder {
    color: ${colors.main500};
    font-size: 1.4rem;
    font-style: italic;
  }
  &::selection {
    background: ${(p) =>
      p.required && !p.error ? colors.warning300 : colors.primary400};
  }
  text-align: ${(p) => (p.formType === 'quantity' ? 'center' : 'unset')};
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-ms-reveal,
  ::-ms-clear {
    display: none;
  }
`;

export const TextInput = styled.input<InputProps>`
  ${BaseStyle};
  ${TextInputTypeStyle};
`;

export const TextArea = styled.textarea<{
  error?: boolean;
  required?: boolean;
  disabled?: boolean;
}>`
  ${BaseStyle};
  ${TextInputTypeStyle};
`;

export const LabelArea = styled.label<InputProps>`
  ${LabelTypeStyle};
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.4rem;
`;

export const TextInputContainer = styled.div<{
  error?: boolean;
  disabled?: boolean;
}>`
  display: flex;
  flex-direction: column;
  &:focus-within ${LabelArea} {
    color: ${(p) => (p.error ? colors.critical700 : colors.primary700)};
  }
`;

export const AsteriskBox = styled.span<{ error?: boolean; disabled?: boolean }>`
  color: ${(p) => AsteriskColor(p.error, p.disabled)};
  font-size: 1.2rem;
  line-height: 2rem;
`;

export const HintArea = styled.span<{ error: boolean }>`
  color: ${(p) => (p.error ? colors.critical700 : colors.main500)};
  align-items: flex-start;
  position: relative;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 2rem;
  display: flex;
  gap: 0.4rem;
`;

export const ErrorContainer = styled.div`
  padding-top: 0.6rem;
`;

export const EyeIcon = styled(BsFillEyeFill)`
  width: 2.4rem;
  height: 2.4rem;
  margin: auto;
  color: ${colors.main500};
`;

export const EyeSlashIcon = styled(BsFillEyeSlashFill)`
  width: 2.4rem;
  height: 2.4rem;
  margin: auto;
  color: ${colors.main500};
`;

export const DollarIcon = styled(BsCurrencyDollar)`
  margin: auto;
  color: ${colors.main500};
`;

export const IconContainer = styled.button<{
  inputDisabled?: boolean;
  error?: boolean;
}>`
  border-radius: 50%;
  margin: 0.3rem 0;
  position: relative;
  background-color: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  ${(p) => (p.inputDisabled ? makeDisabled : null)}
  &:hover {
    background-color: ${(p) =>
      p.inputDisabled ? colors.main200 : colors.primary200};
  }
  &:active {
    background-color: ${colors.primary300};
    opacity: 0.9;
  }
  &:active * {
    color: ${colors.primary700};
  }
  &:focus-visible {
    background-color: ${colors.primary300};
  }
`;

export const HintText = styled.span<{ hasError?: boolean }>`
  padding-top: 0.4rem;
  color: ${(p) => (p.hasError ? colors.critical700 : colors.main500)}; ;
`;

export const CloseIcon = styled(BsDash)`
  width: 2.4rem;
  height: 2.4rem;
  margin: 0.25rem;
  position: absolute;
  top: 0.4rem;
  left: 0.3rem;
  color: ${colors.main500};
`;

export const PlusIcon = styled(BsPlus)`
  width: 2.4rem;
  height: 2.4rem;
  position: absolute;
  top: 0.4rem;
  left: 0.3rem;
  color: ${colors.main500};
`;

export const InfoIcon = styled(MdError)`
  width: 1.6rem;
  height: 1.6rem;
  margin: auto;
  color: ${colors.critical700};
`;

export const CancelIcon = styled(BsXCircleFill)`
  width: 1.6rem;
  height: 1.6rem;
  padding-top: 25%;
  color: ${colors.main500};
`;

export const ListIcon = styled(BsListOl)`
  width: 1.6rem;
  height: 1.6rem;
  padding-top: 25%;
  color: ${colors.main500};
`;

export const ToolTip = styled.div`
  position: absolute;
  right: 0;
  background-color: ${colors.main900};
  min-height: 2.4rem;
  padding: 0.1rem 1.2rem;
  opacity: 0.9;
  border-radius: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: ${colors.main200};
`;

export const DefaultContainer = styled.button`
  border-radius: 50%;
  width: 3.2rem;
  height: 3.2rem;
  position: relative;
  margin: auto;
  background-color: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: none;
  outline: none;
`;
