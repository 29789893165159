import React from 'react';
import { VetGenusLogoDark } from '../media/appLogos';
import ENG from '../translation/translationEn.json';
import FR from '../translation/translationFr.json';
import {
  Page403Container,
  P403TypoH3,
  P403TypoH6,
  Page403ContentContainer,
  Page403HeaderContainer,
  PageHeading,
} from './Page403.style';

export interface Page403Props {
  lang?: 'en' | 'fr';
  header?: React.ReactNode;
}

const Page403: React.FC<Page403Props> = ({ lang = 'en', header }) => {
  const translation = lang === 'fr' ? FR : ENG;

  return (
    <Page403Container>
      {header}
      <div>
        <Page403HeaderContainer>
          <VetGenusLogoDark width="191.4px" height="40px" />
        </Page403HeaderContainer>
        <Page403ContentContainer>
          <img
            src="https://abc-public.s3.ca-central-1.amazonaws.com/img/common/errors/403.png"
            width="490"
            height="200"
            alt={'403'}
          />
          <PageHeading> {translation.page403.accessDenied}</PageHeading>
          <P403TypoH3>{translation.page403.permission}</P403TypoH3>
          <P403TypoH6>{translation.page403.apology}</P403TypoH6>
        </Page403ContentContainer>
      </div>
    </Page403Container>
  );
};

export default Page403;
