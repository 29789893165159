import styled, { keyframes } from 'styled-components';
import { rgba } from 'polished';
import { MODAL_Z_INDEX } from '../consts';
import { colors, devices } from '../variables';

const scaleToOut = keyframes`
    0% {
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        transform: scale(0);
    }

    100% {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: scale(1);
    }
`;

const moveToTop = keyframes`
    0% {
        top: 100%;
    }

    100% {
        top: 0;
    }
`;

export const ModalContainer = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'grid' : 'none')};
  justify-content: center;
  align-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${rgba(colors.primaryLightGray, 0.7)};
  width: 100%;
  height: 100%;
  z-index: ${MODAL_Z_INDEX};
  animation-name: ${scaleToOut};
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;

  @media ${devices.tabLand} {
    animation-name: ${moveToTop};
  }
`;

export const ModalContent = styled.div`
  background: ${colors.white};
  padding: 5rem;
  border-radius: 0.3rem;
  box-shadow: 1px 1px 10px 1px ${colors.primaryGray};

  @media ${devices.phone} {
    padding: 1rem;
  }
`;
