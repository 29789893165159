import React from 'react';
import styled from 'styled-components';
import { DeleteIcon } from '../../DeleteIcon';

const Container = styled.div`
  display: grid;
  grid-template-rows: min-content;
  gap: 0.75rem 0;
  margin-left: 0.5rem;
`;

export interface DischargeDeleteIconProps {
  size?: number;
  onDelete?: () => void;
}

const DischargeDeleteIcon: React.FC<DischargeDeleteIconProps> = ({
  size,
  onDelete,
}) => {
  return (
    <Container>
      <br />
      <DeleteIcon size={size} onClick={onDelete} />
    </Container>
  );
};

export default DischargeDeleteIcon;
