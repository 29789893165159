import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { colors } from '../variables';

const getSwitchStyle = (): FlattenSimpleInterpolation => {
  return css`
    &.MuiSwitch-root {
      background-color: unset;
      border-radius: 1.6rem;
      font-size: 0;
      display: inline-block;
      width: 4.4rem;
      height: 2.4rem;
      margin: 0.4rem 1rem;
      cursor: pointer;
    }
    &.MuiSwitch-root .MuiSwitch-switchBase .MuiSwitch-thumb {
      display: block;
      width: 2rem;
      height: 2rem;
      border-radius: 1.6rem;
    }
    &.MuiSwitch-root .MuiSwitch-switchBase {
      position: absolute;
      left: -8%;
      top: -15%;
      width: 3.2rem;
      height: 3.2rem;
      padding: 0.2rem;
      &:hover {
        background-color: rgba(185, 192, 199, 0.3);
      }
      &:active {
        background-color: rgba(185, 192, 199, 0.5);
      }
    }
    &.MuiSwitch-root .MuiSwitch-track {
      background-color: ${colors.main500};
      display: block;
      position: absolute;
      width: 4.4rem;
      height: 2.4rem;
      left: 0;
      top: 0;
      opacity: 1;
    }
    &.MuiSwitch-root .MuiSwitch-switchBase {
      &.Mui-focusVisible {
        background-color: rgba(185, 192, 199, 0.7);
      }
    }
    &.MuiSwitch-root .MuiSwitch-switchBase.Mui-checked {
      color: ${colors.white};
      .MuiSwitch-root {
        background-color: ${colors.primary700};
      }
      + .MuiSwitch-track {
        background-color: ${colors.primary700};
        opacity: 1;
      }
      &:hover {
        background-color: rgba(245, 252, 255, 0.9);
      }
    }
    &.MuiSwitch-root .MuiSwitch-switchBase.Mui-checked {
      &.Mui-focusVisible {
        background-color: rgba(209, 242, 255, 0.9);
      }
    }

    &.MuiSwitch-root.Mui-disabled {
      color: ${colors.main400};
    }
  `;
};

const Container = styled.div`
  display: flex;
`;
const TextContainer = styled.div`
  display: grid;
`;

const SwitchText = styled.span<{ disabled?: boolean }>`
  font-size: 1.4rem;
  font-style: normal;
  font-style: normal;
  font-family: 'roboto';
  line-height: 22px;
  color: ${({ disabled }) => (disabled ? colors.main400 : colors.main900)};
`;

const SwitchHint = styled.span<{ disabled?: boolean }>`
  font-size: 1.2rem;
  font-weight: normal;
  font-style: normal;
  font-family: 'roboto';
  line-height: 20px;
  color: ${({ disabled }) => (disabled ? colors.main400 : colors.main500)};
`;

export { getSwitchStyle, Container, TextContainer, SwitchText, SwitchHint };
