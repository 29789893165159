import React from 'react';
import styled from 'styled-components';
import {
  Breadcrumbs as BreadcrumbsMUI,
  BreadcrumbsProps as BreadcrumbsPropsMUI,
} from '@mui/material';
import { IoChevronForwardOutline as ChevronForwardIcon } from 'react-icons/io5';
import { colors } from '../variables';
import NavLink from '../NavLink/NavLink';
import { TypoBody2 } from '../Typography';

interface LinkI {
  label: string;
  path: string;
}

const BaseBreadcrumbs = styled(BreadcrumbsMUI)`
  &.MuiBreadcrumbs-root .MuiBreadcrumbs-separator {
    color: ${colors.main500};
  }
`;

const defaultChevronStyle: React.CSSProperties = {
  width: '12px',
  height: '12px',
};

export interface BreadcrumbsProps extends BreadcrumbsPropsMUI {
  breadcrumbs: LinkI[];
  chevronStyle?: React.CSSProperties;
  forceActiveEnd?: boolean;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  breadcrumbs,
  chevronStyle,
  forceActiveEnd,
}) => {
  const renderBreadcrumbs = () =>
    breadcrumbs?.map((hb, index) => (
      <NavLink
        key={hb.path}
        to={hb.path}
        exact
        forceActive={forceActiveEnd && index === breadcrumbs.length - 1}
      >
        <TypoBody2>{hb.label}</TypoBody2>
      </NavLink>
    ));

  return (
    <BaseBreadcrumbs
      separator={
        <ChevronForwardIcon style={chevronStyle || defaultChevronStyle} />
      }
    >
      {renderBreadcrumbs()}
    </BaseBreadcrumbs>
  );
};

export default Breadcrumbs;
