import { Styles, GroupTypeBase } from 'react-select';
import { colors } from '../../variables';
import { DropdownOptionType, menuHorizontalPlacementT } from '../DropdownTypes';

export const isMenuWidthDynamic = (
  dynamicMenuWidth?: boolean,
  selectWidth?: number
): string | number => {
  const defaultWidth = selectWidth || '100%';
  return dynamicMenuWidth ? '' : defaultWidth;
};

const handleMenuPlacement = (
  menuHorizontalPlacement: menuHorizontalPlacementT
) => {
  return menuHorizontalPlacement === 'left' ? { right: 0 } : { left: 0 };
};

export const getBorderColor = (
  hasError: boolean,
  menuIsOpen: boolean | undefined,
  hideBorder: boolean
): string => {
  if (hasError) {
    return colors.critical700;
  }
  if (hideBorder) {
    return 'transparent';
  }
  return menuIsOpen ? colors.primary700 : colors.main400;
};
export const getControlBGColor = (
  required: boolean,
  isDisabled: boolean | undefined
): string => {
  if (isDisabled) {
    return colors.main200;
  }
  return required ? colors.primary300 : colors.white;
};

const getOptionBGColor = (isFocused: boolean, isSelected: boolean) => {
  if (isSelected) {
    return colors.primary300;
  }
  if (isFocused) {
    return colors.primary200;
  }
  return 'inherit';
};

const dropdownStyles:
  | Partial<
      Styles<DropdownOptionType, boolean, GroupTypeBase<DropdownOptionType>>
    >
  | undefined = {
  // chevron
  dropdownIndicator: (
    provided,
    { selectProps: { hideDropdownIndicator } }
  ) => ({
    ...provided,
    // display: hideDropdownIndicator ? 'none' : 'block',
    // using opacity instead of display none prevents a change in height
    opacity: hideDropdownIndicator ? 0 : 1,
  }),
  // The line that separates the chevron and the input
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  placeholder: (provided) => ({
    ...provided,
    fontStyle: 'italic',
    fontFamily: 'roboto',
    color: colors.main500,
  }),
  control: (
    provided,
    {
      selectProps: {
        required,
        isDisabled,
        hasError,
        hideBorder,
        menuIsOpen,
        selectWidth,
      },
    }
  ) => ({
    ...provided,
    width: selectWidth || 'auto',
    backgroundColor: getControlBGColor(required, isDisabled),
    borderColor: getBorderColor(hasError, menuIsOpen, hideBorder),
    borderRadius: '2px',
    boxShadow: 'none',
    '&:hover': {
      borderColor: menuIsOpen ? colors.primary700 : colors.main500,
    },
    '&:focus-within': {
      borderColor: hasError ? colors.critical700 : colors.primary700,
    },
  }),
  menu: (
    provided,
    {
      selectProps: {
        selectWidth,
        dynamicMenuWidth,
        menuHorizontalPlacement,
        menuTop,
      },
    }
  ) => ({
    ...provided,
    whiteSpace: 'nowrap',
    fontFamily: 'roboto',
    borderRadius: '2px',
    width: isMenuWidthDynamic(dynamicMenuWidth, selectWidth),
    top: menuTop || 'auto',
    ...handleMenuPlacement(menuHorizontalPlacement),
  }),
  menuList: (provided, { selectProps: { height } }) => ({
    ...provided,
    lineHeight: height ? `${height / 2}rem` : 'inherit',
    '::-webkit-scrollbar': {
      width: '0px',
    },
  }),
  menuPortal: (provided) => ({
    ...provided,
    fontSize: 'inherit',
  }),
  option: (provided, { isFocused, isSelected, isDisabled }) => {
    return {
      ...provided,
      background: getOptionBGColor(isFocused, isSelected),
      color: isDisabled ? colors.main400 : colors.main900,
      fontWeight: isSelected ? '500' : '400',
      '&:hover': {
        background: colors.primary200,
      },
    };
  },
  valueContainer: (provided, { selectProps: { selectWidth } }) => ({
    ...provided,
    width: selectWidth || 'auto',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontFamily: 'roboto',
    // background: colors.primary400,
  }),
  group: (provided, { label }) => ({
    ...provided,
    borderBottom: !label ? `1px solid ${colors.main200}` : '',
  }),
};

export default dropdownStyles;
