import styled, { css } from 'styled-components';
import '@fontsource/roboto';
import { colors } from '../variables';
import noScrollBar from '../mixins/common';

export const Container = styled.div<{ collapsed?: boolean }>`
  border: 1px solid ${colors.main400};
  border-radius: 2px;
`;

export const Title = styled.div<{
  collapsed?: boolean;
  innerSection?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  font-size: ${({ innerSection }) => (innerSection ? '1.5rem' : '1.7rem')};
  border-bottom: ${({ collapsed }) =>
    collapsed ? `1px solid ${colors.main400}` : 'none'};
  font-weight: 500;
`;

export const TitleExtraContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-right: 1.6rem;
  color: ${colors.main400};
  gap: 0 1rem;
  width: max-content;
  align-items: center;
  & > svg {
    cursor: pointer;
    font-size: 1.5rem;
  }
`;

const getCollapseAnimation = (collapsible?: boolean, collapsed?: boolean) => {
  if (!collapsible) return css``;
  return css`
    max-height: ${collapsed ? '400rem' : 0};
    -webkit-transition: max-height 0.1s ease-in, padding 0.1s ease-in;
    -moz-transition: max-height 0.1s ease-in, padding 0.1s ease-in;
    transition: max-height 0.1s ease-in, padding 0.1s ease-in;
  `;
};

export const Content = styled.div<{
  collapsible?: boolean;
  collapsed?: boolean;
  contentHeight?: string;
  hiddenScrollbar?: boolean;
}>`
  overflow-y: auto;
  height: ${(p) => p.contentHeight || '100%'};
  ${(p) => getCollapseAnimation(p.collapsible, p.collapsed)}
  ${({ hiddenScrollbar }) => hiddenScrollbar && noScrollBar}
`;

export const SectionPairTitleDefault = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  padding: 1.2rem 1.6rem;
  span:first-child {
    font-family: 'Roboto';
    margin: 0 0.8rem;
    margin-left: 0;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.9rem;
    color: ${colors.main900};
  }
  span:last-child {
    font-family: 'Roboto';
    margin: 0 0.8rem;
    font-size: 1.4rem;
    font-weight: normal;
    font-style: italic;
    line-height: 2.2rem;
    color: ${colors.main500};
  }
`;
