import React, { useState, useEffect, useRef } from 'react';
import Select, { OptionsType, Props, ActionMeta } from 'react-select';
import { IoMedical } from 'react-icons/io5';
import useWindowDimensions from '../../hooks/useWindowDimensions/useWindowDimensions';
import { DropdownOptionType, menuHorizontalPlacementT } from '../DropdownTypes';
import { HintArea, CustomOptionFormat } from '../components';
import {
  TextInputContainer,
  LabelArea,
  AsteriskBox,
} from '../../FormInput/FormInput.style';
import dropdownStyles from './Dropdown.style';

export interface DropdownProps extends Props<DropdownOptionType, boolean> {
  oneSelectHandler?: (item: DropdownOptionType) => void;
  multiSelectHandler?: (items: OptionsType<DropdownOptionType>) => void;
  dynamicMenuWidth?: boolean;
  selectWidth?: number;
  label?: string;
  hint?: string;
  hasError?: boolean;
  hideBorder?: boolean;
  hideDropdownIndicator?: boolean;
  singleClearHandler?: () => void;
  menuHorizontalPlacement?: menuHorizontalPlacementT;
  menuTop?: string | number;
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  isMulti,
  oneSelectHandler,
  multiSelectHandler,
  menuShouldBlockScroll = true,
  hasError,
  isDisabled,
  required,
  label,
  hint,
  hideBorder,
  hideDropdownIndicator,
  components,
  singleClearHandler,
  menuHorizontalPlacement,
  menuTop,
  ...props
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>();
  const [menuPosition, setMenuPosition] =
    useState<menuHorizontalPlacementT>('right');
  const { windowWidth } = useWindowDimensions();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectRef = useRef<any>(null);

  useEffect(() => {
    if (typeof props.menuIsOpen !== 'undefined') {
      setMenuIsOpen(props.menuIsOpen);
    }
  }, [props.menuIsOpen]);

  const onMenuOpen = () => {
    if (props.onMenuOpen) {
      props.onMenuOpen();
    }
    setTimeout(() => {
      if (selectRef.current.select) {
        const dropdownElement = selectRef.current.select.controlRef;
        const remainingRightSpace =
          windowWidth - dropdownElement.getBoundingClientRect().right;
        const menuElement = selectRef.current.select.menuListRef;

        if (menuElement) {
          const rightSpaceAllowed = menuElement.getBoundingClientRect().width;
          if (remainingRightSpace < rightSpaceAllowed) {
            setMenuPosition('left');
          } else {
            setMenuPosition('right');
          }
        }
      }
    }, 1);
  };

  const close = () => {
    setMenuIsOpen(false);
    if (selectRef.current) {
      const ref = selectRef.current as unknown as HTMLSelectElement;
      ref.blur();
    }
  };

  const handleChange = (
    selected: DropdownOptionType | OptionsType<DropdownOptionType> | null,
    event: ActionMeta<DropdownOptionType>
  ) => {
    if (props.closeMenuOnSelect !== false) close();

    if (event.action === 'clear' && singleClearHandler) {
      singleClearHandler();
    }

    if (selected) {
      if (!isMulti && oneSelectHandler) {
        oneSelectHandler(selected as DropdownOptionType);
      } else if (isMulti && multiSelectHandler) {
        multiSelectHandler(
          selected.length ? (selected as OptionsType<DropdownOptionType>) : []
        );
      }
    }
  };

  const renderDropdown = () => {
    return (
      <Select
        options={options}
        onChange={handleChange}
        isMulti={isMulti}
        onMenuOpen={onMenuOpen}
        menuIsOpen={menuIsOpen}
        onFocus={(e) => {
          setMenuIsOpen(true);
          if (props.onFocus) props.onFocus(e);
        }}
        onBlur={(e) => {
          setMenuIsOpen(false);
          if (props.onBlur) props.onBlur(e);
        }}
        styles={{ ...dropdownStyles, ...props.styles }}
        ref={selectRef}
        hasError={hasError}
        isDisabled={isDisabled}
        required={required}
        hideBorder={hideBorder}
        hideDropdownIndicator={hideDropdownIndicator}
        menuShouldBlockScroll={menuShouldBlockScroll}
        formatOptionLabel={CustomOptionFormat}
        components={components}
        menuHorizontalPlacement={menuHorizontalPlacement || menuPosition}
        menuTop={menuTop}
        {...props}
      />
    );
  };

  return (
    <TextInputContainer error={hasError} disabled={isDisabled}>
      <LabelArea error={hasError}>
        {label && (
          <>
            {label}{' '}
            {required && (
              <AsteriskBox error={hasError}>
                <IoMedical />
              </AsteriskBox>
            )}
          </>
        )}
      </LabelArea>
      {renderDropdown()}
      {hint && <HintArea hasError={hasError} hint={hint} />}
    </TextInputContainer>
  );
};

export default Dropdown;
