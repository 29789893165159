import React from 'react';
import styled from 'styled-components';
import Checkbox, {
  CheckboxProps as CheckboxMUIProps,
} from '@mui/material/Checkbox';
import {
  getCheckboxStyle,
  CheckboxText,
  CheckboxHint,
  Container,
  TextContainer,
} from './Checkbox.style';

export interface CheckboxProps extends CheckboxMUIProps {
  text?: string;
  hint?: string;
}

const CheckboxMUI = styled(Checkbox)`
  ${getCheckboxStyle}
`;

const CustomCheckbox: React.FC<CheckboxProps> = ({
  text,
  hint,
  indeterminate,
  disabled,
  className,
  ...props
}) => {
  return (
    <Container className={className}>
      <div>
        <CheckboxMUI
          indeterminate={indeterminate}
          disabled={disabled}
          disableRipple={true}
          {...props}
        />
      </div>
      <TextContainer>
        {text && <CheckboxText disabled={disabled}>{text}</CheckboxText>}
        {hint && <CheckboxHint disabled={disabled}>{hint}</CheckboxHint>}
      </TextContainer>
    </Container>
  );
};

export default CustomCheckbox;
