import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { BaseStyle, BtnTypeStyle } from './Button.style';
import { ButtonProps } from './ButtonTypes';
import { colors } from '../variables';

export const DisabledStyle = ({
  disabled,
}: {
  disabled?: boolean;
}): FlattenSimpleInterpolation =>
  disabled
    ? css`
        color: ${colors.primaryDarkGray};
        background-color: transparent;
        border-color: ${colors.primaryDarkGray};
      `
    : css``;

const ButtonLink = styled.a<ButtonProps>`
  ${BaseStyle}
  ${BtnTypeStyle}
  ${DisabledStyle}
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-decoration: none;
  pointer-events: ${(p) => (p.disabled ? 'none' : 'inherit')};
`;

export default ButtonLink;
