import { css, FlattenSimpleInterpolation } from 'styled-components';
import { colors } from '../variables';

const getAlertBannerStyle = (): FlattenSimpleInterpolation => {
  return css`
    &.MuiAlert-root {
      color: ${colors.main900};
      font-style: normal;
      font-family: Roboto;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
    }
    &.MuiAlert-standardSuccess {
      border: 1px solid ${colors.primaryGreen};
    }
    &.MuiAlert-standardSuccess .MuiAlert-icon {
      & > svg {
        color: ${colors.primaryGreen};
        & > path {
          fill: inherit;
        }
      }
    }
    &.MuiAlert-standardError {
      border: 1px solid ${colors.primaryRed};
    }
    &.MuiAlert-standardError .MuiAlert-icon {
      & > svg {
        color: ${colors.primaryRed};
        & > path {
          fill: inherit;
        }
      }
    }
    &.MuiAlert-standardInfo {
      color: ${colors.main900};
      border: 1px solid ${colors.info700};
    }
    &.MuiAlert-standardInfo .MuiAlert-icon {
      & > svg {
        color: ${colors.info700};
        & > path {
          fill: inherit;
        }
      }
    }
    &.MuiAlert-standardWarning {
      border: 1px solid ${colors.warning700};
    }
    &.MuiAlert-standardWarning .MuiAlert-icon {
      & > svg {
        color: ${colors.warning700};
        & > path {
          fill: inherit;
        }
      }
    }
    &.MuiAlert-outlinedSuccess {
      border: 1px solid ${colors.primaryGreen};
    }
    &.MuiAlert-outlinedSuccess .MuiAlert-icon {
      & > svg {
        color: ${colors.primaryGreen};
        & > path {
          fill: inherit;
        }
      }
    }
    &.MuiAlert-outlinedError {
      border: 1px solid ${colors.primaryRed};
    }
    &.MuiAlert-outlinedError .MuiAlert-icon {
      & > svg {
        color: ${colors.primaryRed};
        & > path {
          fill: inherit;
        }
      }
    }
    &.MuiAlert-outlinedInfo {
      color: ${colors.main900};
      border: 1px solid ${colors.info700};
    }
    &.MuiAlert-outlinedInfo .MuiAlert-icon {
      & > svg {
        color: ${colors.info700};
        & > path {
          fill: inherit;
        }
      }
    }
    &.MuiAlert-outlinedWarning {
      border: 1px solid ${colors.warning700};
    }
  `;
};

export default getAlertBannerStyle;
