import { DropdownOptionType } from '../../Dropdown';
import { PHMainMenuOptionsT } from './types';

export const constructMainMenuOptions = (
  apps: PHMainMenuOptionsT
): DropdownOptionType[] => {
  return apps.map((app) => ({
    label: app.label,
    value: app.value,
    url: app.url,
    iconType: app.value,
    onClick: app.onClick,
  }));
};
