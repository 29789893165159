import styled, { css } from 'styled-components';
import { colors } from '../variables';

export type InputType = 'primary' | 'secondary';

export interface InputProps {
  inputType?: InputType;
}

const getInputs = (inputType?: InputType) => () => {
  switch (inputType) {
    case 'secondary':
      return css`
        border: 1px solid ${colors.primaryBlack};
        background: ${colors.primaryGray};

        &:hover:not([disabled]),
        &:focus,
        &:active {
          border: 1px solid ${colors.primaryDarkGray};
        }
      `;
    default:
      return css`
        border: 1px solid ${colors.primaryDarkGray};

        &:hover:not([disabled]),
        &:focus,
        &:active {
          border: 1px solid ${colors.main};
        }
      `;
  }
};

export default styled.input<InputProps>`
  display: block;
  border-radius: 2px;
  padding: 0.2rem;
  font-size: inherit;
  font-family: inherit;

  ${({ inputType }) => getInputs(inputType)}

  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
    outline: none;
  }
`;
