import React from 'react';
import styled from 'styled-components';

const Container = styled.div<{ position: PHOptionsPositionT }>`
  display: flex;
  justify-content: ${({ position }) =>
    position === 'right' ? 'end' : 'start'};
  align-items: center;
  gap: 5px;
`;

export type PHOptionsPositionT = 'right' | 'left';
export interface PHOptionsContainerProps {
  children: React.ReactNode;
  className?: string;
  position?: PHOptionsPositionT;
}

const PHOptionsContainer: React.FC<PHOptionsContainerProps> = ({
  className,
  position = 'right',
  children,
}) => {
  return (
    <Container className={className} position={position}>
      {children}
    </Container>
  );
};

export default PHOptionsContainer;
