import styled from 'styled-components';
import { colors } from '../../../variables';

export const Table = styled.table`
  border: 1px solid ${colors.primaryDarkGray};
  border-collapse: collapse;
`;

export const TableHead = styled.thead`
  background: ${colors.primaryLightGray};
  border-bottom: 1px solid ${colors.primaryDarkGray};
`;

export const TableBody = styled.tbody`
  & > tr {
    border-bottom: 1px solid ${colors.primaryDarkGray};
  }

  tr:last-child {
    border: none;
  }
`;

export const TableTd = styled.td`
  border: none;
`;

export const TableTh = styled(TableTd)`
  color: ${colors.main};
  font-weight: 500;
`;
