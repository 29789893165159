import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { FormatOptionLabelMeta } from 'react-select';
import {
  PlusIcon,
  PersonIcon,
  PersonOutlineIcon,
  QuestionCirlceIcon,
  LockClosedIcon,
  BsBoxArrowRight,
} from '../../variables/icons';
import { DropdownOptionIconT, DropdownOptionType } from '../DropdownTypes';
import {
  PetGenusLogo,
  RoundsLogo,
  WhiteboardLogo,
  ResearchGenusLogo,
  OpsGenusColorLogo,
} from '../../media';
import { APP_KEYS } from '../../consts';

const ConsultCard = styled.div`
  display: flex;
`;

const IconContainer = styled.div`
  padding-right: 1rem;
`;

type CustomOptionFormatType = (
  option: DropdownOptionType,
  labelMeta: FormatOptionLabelMeta<DropdownOptionType, boolean>
) => ReactNode;

const renderIcon = (iconType?: DropdownOptionIconT) => {
  const commonIconProps = { size: '20px' };
  const commonSvgIconProps = { height: '20px', width: '20px' };
  switch (iconType) {
    case 'plus':
      return <PlusIcon {...commonIconProps} />;
    case 'person':
      return <PersonIcon {...commonIconProps} />;
    case APP_KEYS.RESEARCH_GENUS:
      return <ResearchGenusLogo {...commonSvgIconProps} />;
    case APP_KEYS.OPS_GENUS:
      return <OpsGenusColorLogo {...commonSvgIconProps} />;
    case APP_KEYS.WHITEBOARD:
      return <WhiteboardLogo {...commonSvgIconProps} />;
    case APP_KEYS.ROUNDS:
      return <RoundsLogo {...commonSvgIconProps} />;
    case APP_KEYS.PET_GENUS:
      return <PetGenusLogo {...commonSvgIconProps} />;
    case 'profile':
      return <PersonOutlineIcon {...commonIconProps} />;
    case 'help':
      return <QuestionCirlceIcon {...commonIconProps} />;
    case 'admin':
      return <LockClosedIcon {...commonIconProps} />;
    case 'signOut':
      return <BsBoxArrowRight {...commonIconProps} />;
    default:
      return null;
  }
};

const CustomOptionFormat: CustomOptionFormatType = ({ label, iconType }) => {
  return (
    <ConsultCard>
      {iconType && <IconContainer>{renderIcon(iconType)}</IconContainer>}
      <label>{label}</label>
    </ConsultCard>
  );
};

export default CustomOptionFormat;
