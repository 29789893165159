/* eslint-disable import/prefer-default-export */
const hours = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

const getTwoDigitNumber = (num: number) => {
  return num > 9 ? `${num}` : `0${num}`;
};

const getPmOrAmTimeArray = (timeType: 'pm' | 'am') => {
  const timeArray = [];
  for (let i = 0; i < hours.length; i += 1) {
    for (let j = 0; j <= 45; j += 15) {
      const newTime = `${getTwoDigitNumber(hours[i])}:${getTwoDigitNumber(
        j
      )} ${timeType}`;
      timeArray.push(newTime);
    }
  }
  return timeArray;
};

export const getTimeArray = (): string[] => [
  ...getPmOrAmTimeArray('am'),
  ...getPmOrAmTimeArray('pm'),
];
