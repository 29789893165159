import styled, { keyframes } from 'styled-components';
import { colors } from '../variables';

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  
  to {
    transform: rotate(360deg);
  }
`;

export default styled.div`
  height: 3rem;
  width: 3rem;
  border: 1px solid ${colors.secondaryDarkBlue};
  border-radius: 50%;
  border-top: none;
  border-right: none;
  margin: auto auto;
  animation: ${rotation} 1s linear infinite;
`;
