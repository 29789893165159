import React from 'react';
import { Dropdown, DropdownProps, dropdownStyles } from '../../Dropdown';
import { ValueContainer } from '../../Dropdown/ActionDropdown/components';
import { SingleValue } from './components';
import { PHAppMenuOptionsI } from './types';
import { constructAppOptions } from './utils';
import { PHMenuStyles } from '../common';

export interface PHAppMenuProps extends DropdownProps {
  apps: PHAppMenuOptionsI[];
}

const PHAppMenu: React.FC<PHAppMenuProps> = ({ apps, ...props }) => {
  return (
    <Dropdown
      styles={{ ...dropdownStyles, ...PHMenuStyles, ...props.styles }}
      options={constructAppOptions(apps)}
      oneSelectHandler={(option) => window.open(option.value)}
      components={{
        ...props.components,
        SingleValue,
        ValueContainer,
        DropdownIndicator: () => null,
      }}
      minMenuWidth="max-content"
      value={{ label: '', value: '' }}
      isSearchable={false}
      dynamicMenuWidth={true}
      {...props}
    />
  );
};

export default PHAppMenu;
