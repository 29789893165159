import React, { useMemo } from 'react';
import { getOptions, getTimeArray } from '../utils';
import { Select } from '../Select';

export interface TimePickerProps {
  timeChangeHandler: (time: string) => void;
  selectedTime?: string | null;
  className?: string;
}

const TimePicker: React.FC<TimePickerProps> = ({
  timeChangeHandler,
  selectedTime,
  className,
}) => {
  const timeOptions = useMemo(() => getOptions(getTimeArray()), []);

  return (
    <Select
      options={timeOptions}
      selectedOption={selectedTime || null}
      onChangeOption={(item) => timeChangeHandler(item.value as string)}
      className={className}
      placeholder="Select Time"
    />
  );
};

export default TimePicker;
