import React, { useEffect } from 'react';
import { ModalContainer, ModalContent } from './Modal.style';
import useDidUpdate from '../hooks/useDidUpdate/useDidUpdate';

export type ModalChildren = React.ReactNode;

export interface ModalProps {
  children: ModalChildren;
  className?: string;
  show?: boolean;
  backgroundClick?: () => void;
  contentStyle?: React.CSSProperties;
  preventBackgroundScroll?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  show = false,
  children,
  backgroundClick,
  className,
  contentStyle,
  preventBackgroundScroll = false,
}) => {
  useEffect(() => {
    if (show && preventBackgroundScroll) {
      document.body.style.overflowY = 'hidden';
    }
  });

  useDidUpdate(() => {
    if (!show) {
      document.body.style.overflowY = 'auto';
    }
  }, [show]);

  return (
    <ModalContainer
      className={className}
      show={show}
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          return backgroundClick ? backgroundClick() : undefined;
        }
        return null;
      }}
    >
      <ModalContent style={contentStyle}>{children}</ModalContent>
    </ModalContainer>
  );
};

export default Modal;
