import React from 'react';
import styled from 'styled-components';
import { components, OptionProps } from 'react-select';
import { DropdownOptionType } from '../../DropdownTypes';
import { Checkbox } from '../../../Checkbox';

const CheckboxCard = styled.div`
  display: flex;
  align-items: center;
`;

const DefaultCheckboxOption: React.FC<
  OptionProps<DropdownOptionType, boolean>
> = (props) => {
  return (
    <components.Option {...props}>
      <CheckboxCard>
        <Checkbox disabled={props.data.disabled} checked={props.isSelected} />
        <label>{props.data.label}</label>
      </CheckboxCard>
    </components.Option>
  );
};

export default DefaultCheckboxOption;
