import React from 'react';
import { GroupTypeBase, SingleValueProps } from 'react-select';
import pHeaderIconBaseStyle from '../../../pageHeaderCommonStyle';
import { DropdownOptionType } from '../../../../Dropdown';
import { PHSingleValueContainer } from '../../../common';
import { PHMenuInitialsSpan } from './SingleValue.style';

const SingleValue:
  | React.FC<
      SingleValueProps<DropdownOptionType, GroupTypeBase<DropdownOptionType>>
    >
  | undefined = (props) => {
  return (
    <PHSingleValueContainer>
      <PHMenuInitialsSpan style={{ ...pHeaderIconBaseStyle }}>
        {props.selectProps.singleValueProps}
      </PHMenuInitialsSpan>
    </PHSingleValueContainer>
  );
};

export default SingleValue;
