import React from 'react';
import styled from 'styled-components';
import { Tabs as TabsMUI, Tab as TabMUI, TabsProps } from '@mui/material';
import { colors } from '../variables';

const Tab = styled(TabMUI)`
  &.Mui-selected {
    color: ${colors.primary700} !important;
  }
  &.MuiTab-root {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 2rem;
  }
`;

const TabsStyled = styled(TabsMUI)`
  &.MuiTabs-root .MuiTabs-scroller .MuiTabs-indicator {
    background-color: ${colors.primary700};
  }
`;

const Tabs: React.FC<TabsProps> = (props) => {
  return <TabsStyled {...props} />;
};

export { Tab, Tabs };
