import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { colors } from '../variables';

const getCheckboxStyle = (): FlattenSimpleInterpolation => {
  return css`
    &.MuiCheckbox-root {
      color: ${colors.main500};
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 0.4rem;
      padding: 0.8rem;
      &:hover {
        background: ${colors.primary100};
      }
      &:active {
        background: ${colors.primary300};
      }
    }
    &.MuiCheckbox-root.Mui-focusVisible {
      color: transparent;
      background: ${colors.primary200};
      & > svg {
        color: ${colors.main500};
        & > path {
          fill: ${colors.main500};
        }
      }
    }
    &.MuiCheckbox-root.Mui-checked {
      color: ${colors.primary700};
      & > svg {
        color: inherit;
        & > path {
          fill: inherit;
        }
      }
    }
    &.MuiCheckbox-root.MuiCheckbox-indeterminate {
      color: ${colors.primary700};
    }
    &.MuiCheckbox-root.Mui-checked.Mui-disabled {
      color: ${colors.primary300};
      & > svg {
        color: inherit;
        & > path {
          fill: inherit;
        }
      }
    }
    &.MuiCheckbox-root.Mui-disabled {
      color: ${colors.main400};
    }
  `;
};

const Container = styled.div`
  display: flex;
`;

const TextContainer = styled.div<{ allign?: boolean }>`
  display: grid;
  padding-top: 0.7rem;
`;

const CheckboxText = styled.span<{ disabled?: boolean }>`
  font-size: 1.4rem;
  font-style: normal;
  font-style: normal;
  font-family: 'roboto';
  line-height: 2.2rem;
  color: ${({ disabled }) => (disabled ? colors.main400 : colors.main900)};
`;

const CheckboxHint = styled.span<{ disabled?: boolean }>`
  font-size: 1.2rem;
  font-weight: normal;
  font-style: normal;
  font-family: 'roboto';
  line-height: 2rem;
  color: ${({ disabled }) => (disabled ? colors.main400 : colors.main500)};
`;

export {
  getCheckboxStyle,
  Container,
  TextContainer,
  CheckboxText,
  CheckboxHint,
};
