/* eslint-disable import/prefer-default-export */
export const UnitOptions = [
  {
    label: 'year(s)',
    value: 'years',
  },
  {
    label: 'month(s)',
    value: 'months',
  },
  {
    label: 'week(s)',
    value: 'weeks',
  },
  {
    label: 'day(s)',
    value: 'days',
  },
];
