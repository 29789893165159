import React from 'react';
import styled from 'styled-components';
import { components, GroupTypeBase, SingleValueProps } from 'react-select';
import { IoEllipsisVertical } from 'react-icons/io5';
import { colors } from '../../../variables';
import { DropdownOptionType } from '../../DropdownTypes';

export const MenuIcon = styled(IoEllipsisVertical)`
  width: 24px;
  height: 24px;
  color: ${colors.main500};
  cursor: pointer;
  padding: 0;
`;

const SingleValue:
  | React.FC<
      SingleValueProps<DropdownOptionType, GroupTypeBase<DropdownOptionType>>
    >
  | undefined = (props) => {
  return (
    <components.SingleValue {...props}>
      <MenuIcon />
    </components.SingleValue>
  );
};

export default SingleValue;
