import React from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import colors from '../../variables/colors';
import { PageHeaderT, PageHeaderProps } from './PageHeaderTypes';

const getHeaderColors = ({
  headerType,
}: {
  headerType: PageHeaderT;
}): FlattenSimpleInterpolation => {
  switch (headerType) {
    case 'primary':
      return css`
        color: ${colors.white};
        background-color: ${colors.main900};
      `;
    case 'secondary':
      return css`
        color: ${colors.main900};
        background-color: ${colors.white};
      `;
    case 'tertiary':
    default:
      return css`
        color: ${colors.primary700};
        background-color: ${colors.white};
      `;
  }
};

const Container = styled.div<{ headerType: PageHeaderT }>`
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  align-items: center;
  ${getHeaderColors}
  padding: 0px 16px;
  height: 48px;
  box-shadow: 0px 2px 8px 0.8px rgba(6, 31, 58, 0.05);
  font-size: 12px;
  font-weight: 500;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
`;

const PageHeader: React.FC<PageHeaderProps> = ({
  headerType = 'primary',
  headerLeft = <div />,
  headerCenter = <div />,
  headerRight = <div />,
  className,
}) => {
  return (
    <Container headerType={headerType} className={className}>
      {headerLeft}
      {headerCenter}
      {headerRight}
    </Container>
  );
};

export default PageHeader;
