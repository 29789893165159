import React, { useState } from 'react';
import {
  useJsApiLoader,
  Autocomplete,
  AutocompleteProps,
} from '@react-google-maps/api';
import libraries from './LocationSearch.config';
import { FormInput, FormInputProps } from '../FormInput';

export interface LocationI {
  street: string;
  city: string;
  suburb: string;
  region: string;
  state: string;
  postalCode: string;
  country: string;
  latitude: number | string;
  longitude: number | string;
  full_address: string;
  raw_address: Record<string, unknown>;
}
export interface LocationSearchProps
  extends FormInputProps,
    Pick<AutocompleteProps, 'restrictions'> {
  googleMapsAPIKey: string;
  changeHandler: (location: LocationI) => void;
}

const LocationSearch: React.FC<LocationSearchProps> = ({
  googleMapsAPIKey,
  placeholder,
  changeHandler,
  restrictions,
  ...props
}) => {
  const [autocomplete, setAutocomplete] = useState<any>(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-maps-script',
    googleMapsApiKey: googleMapsAPIKey,
    libraries,
  });

  const onChange = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      const location: LocationI = {
        street: '',
        city: '',
        suburb: '',
        region: '',
        state: '',
        postalCode: '',
        country: '',
        latitude: '',
        longitude: '',
        full_address: '',
        raw_address: place,
      };

      place.address_components.forEach((component: any) => {
        const componentType = component.types[0];
        switch (componentType) {
          case 'street_number': {
            location.street = `${component.long_name} ${location.street}`;
            break;
          }

          case 'route': {
            location.street += component.short_name;
            break;
          }

          case 'sublocality_level_1': {
            location.suburb = component.short_name || component.long_name || '';
            break;
          }

          case 'postal_code': {
            location.postalCode = `${component.long_name}${location.postalCode}`;
            break;
          }

          case 'postal_code_suffix': {
            location.postalCode = `${location.postalCode}-${component.long_name}`;
            break;
          }

          case 'locality':
            location.city = component.long_name;
            break;

          case 'administrative_area_level_1': {
            location.state = component.long_name || component.short_name || '';
            break;
          }

          case 'administrative_area_level_2': {
            if (location.region === '') {
              location.region =
                component.short_name || component.long_name || '';
            }
            break;
          }

          case 'administrative_area_level_3': {
            location.region = component.short_name || component.long_name || '';
            break;
          }

          case 'country':
            location.country = component.long_name;
            break;

          default:
            break;
        }
      });

      location.full_address = [
        location.street,
        location.city,
        location.state,
        location.postalCode,
        location.country,
      ]
        .filter((component) => component.length > 0)
        .join(', ');

      const latitude = place?.geometry?.location?.lat();
      const longitude = place?.geometry?.location?.lng();

      location.latitude = typeof latitude === 'number' ? latitude : '';

      location.longitude = typeof longitude === 'number' ? longitude : '';

      changeHandler(location);
    }
  };

  if (isLoaded) {
    return (
      <Autocomplete
        onLoad={setAutocomplete}
        onPlaceChanged={onChange}
        restrictions={restrictions}
      >
        <FormInput placeholder={placeholder || 'Search...'} {...props} />
      </Autocomplete>
    );
  }

  return <></>;
};

export default LocationSearch;
