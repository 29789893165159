import React, { SelectHTMLAttributes, forwardRef } from 'react';
import StyledSelect from './Select.style';

export interface SelectOption {
  label: string;
  value: number | string;
  [key: string]: any;
}

export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  onChangeOption: (selectedOption: SelectOption) => void;
  options: SelectOption[];
  selectedOption?: SelectOption | string | number | null;
  placeholder?: string;
  className?: string;
}

const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      onChangeOption,
      options,
      selectedOption,
      placeholder,
      className,
      ...props
    },
    ref
  ) => {
    const onChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
      onChangeOption(options[e.target.selectedIndex - 1]);
    };

    const getSelectedOption = () => {
      const selectedValue = options.find((o) => {
        if (typeof selectedOption === 'object') {
          return o.value === selectedOption?.value;
        }
        return o.value === selectedOption;
      })?.value;
      return selectedValue || selectedValue === 0 ? selectedValue : '';
    };

    return (
      <StyledSelect
        className={className}
        value={getSelectedOption()}
        onChange={onChangeHandler}
        {...props}
        ref={ref}
      >
        <option disabled value="">
          {placeholder || 'Select'}
        </option>
        {options.map((op) => (
          <option value={op.value} key={op.label}>
            {op.label}
          </option>
        ))}
      </StyledSelect>
    );
  }
);

export default Select;
