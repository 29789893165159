const colors = {
  primaryBlue: '#1D68AC',
  primaryLightBlue: '#3399ff',
  primaryVeryLightBlue: '#7cd5f7',
  primaryDarkBlue: '#134277',
  primaryVeryDarkBlue: '#11335A',
  primaryGray: '#DDDDDD',
  primaryLightGray: '#F3F3F3',
  primaryDarkGray: '#AAAAAA',
  primaryVeryDarkGray: '#4A4A4A',
  primaryBlack: '#000000',
  primaryWhite: '#ffffff',
  primaryRed: '#DA1415',
  primaryBrightRed: '#FF1732',
  primaryWhiteRed: '#FEEFEF',
  primaryYellow: '#FFBE17',
  primaryBlackYellow: '#061F3A',
  primaryWhiteYellow: '#FFF5DA',
  primaryBorderYellow: '#CC9300',
  primaryGreen: '#287D3C',
  primaryWhiteGreen: '#EDF9F0',
  primaryInfo: '#2E5AAC',
  primaryBrightInfo: '#2E77FF',
  primaryWhiteInfo: '#EEF2FA',
  secondaryRed: '#FF0000',
  secondaryBlue: '#1D5799',
  secondaryDarkBlue: '#2B3C90',
  secondaryOrange: '#E5A05F',
  secondaryDarkPink: '#D19D96',
  secondaryYellow: '#F4E4A0',
  secondaryLightRed: '#FF8474',
  regularMediumBlue: '#0F9ED7',
  regularLightBlue: '#7CD5F7',
  regularDarkBlue: '#006086',
  secondaryLightBlue: '#F5FCFF',
  secondaryMediumBlue: '#EAF9FF',
  secondaryLightGray: '#F9FBFD',
  secondaryGray: '#EEF1F4',
  blue70: '#0b3cb6',
  tertiaryMediumBlue: '#4FB8FF',
  white: '#ffffff',
  critical: '#FF1732',
  critical700: '#DA1414',
  critical200: '#FEEFEF',
  critical300: '#F48989',
  cyan50: '#4FB8FF',
  cyan90: '#012749',
  warning200: '#FFF5DA',
  warning300: '#FFDC81',
  warning700: '#CC9300',
  yellow50: '#E5B932',
  success200: '#EDF9F0',
  success300: '#5ACA75',
  success700: '#4FCC32',
  secondaryGreen: '#26BFC7',
  green50: '#4CC270',
  info700: '#2E5AAC',
  info: '#2E77FF',
  info200: '#EEF2FA',
  info300: '#89A7E0',
  orange50: '#FF8473',
  orange70: '#8A3800',
  teal50: '#38FFFF',
  teal70: '#26BFC7',
  primary: '#7CD5F7',
  primary100: '#F5FCFF',
  primary200: '#EAF9FF',
  primary300: '#D1F2FF',
  primary400: '#BEECFD',
  primary700: '#0F9ED7',
  primary800: '#006086',
  main: '#1D68AC',
  main100: '#F9FBFD',
  main200: '#EEF1F4',
  main400: '#B9C0C7',
  main500: '#828D97',
  main600: '#134277',
  main700: '#11335A',
  main900: '#061F3A',
  magenta50: '#FF4596',
  magenta70: '#CA2881',
  purple50: '#964BFF',
  purple70: '#3C00E9',
  primaryPink: '#CA2881',
  red50: '#FA4D56',
  red70: '#B43E44',
  success: '#4FCC32',
  warning: '#FFBE17',
};

export default colors;
