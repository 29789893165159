import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { getYears, getMonthsOptions } from './utils';
import { getOptions, getOption, getYearAndMonth } from '../utils';
import { Select, SelectOption } from '../Select';

const Container = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  gap: 1rem;
`;

export interface YearMonthProps {
  yearMonthChangeHandler: (date_year_month: number) => void;
  value: number | null;
  className?: string;
}

const YearMonth: React.FC<YearMonthProps> = ({
  yearMonthChangeHandler,
  value,
  className,
}) => {
  const [yearState, setYearState] = useState<number>();
  const [monthState, setMonthState] = useState<number>();

  const yearOptions = useMemo(() => getOptions(getYears()), []);
  const monthOptions = useMemo(() => getMonthsOptions(), []);

  useEffect(() => {
    if (value || value === 0) {
      const { year, month } = getYearAndMonth(value as number);
      setYearState(year);
      setMonthState(month);
    } else {
      setYearState(undefined);
      setMonthState(undefined);
    }
  }, [value]);

  const updateYearAndMonth = (newYear: number, newMonth: number) => {
    const newDate = new Date(newYear, newMonth, 1).getTime();
    yearMonthChangeHandler(newDate);
  };

  const dropdownChangeHandler = (
    dropdownValue: number,
    type: 'year' | 'month'
  ) => {
    if (type === 'year') {
      setYearState(dropdownValue);
      if (typeof monthState === 'number') {
        updateYearAndMonth(dropdownValue, monthState);
      }
    } else {
      setMonthState(dropdownValue);
      if (yearState) {
        updateYearAndMonth(yearState, dropdownValue);
      }
    }
  };

  return (
    <Container className={className}>
      <Select
        options={yearOptions}
        selectedOption={
          yearState ? (getOption(yearState) as SelectOption) : undefined
        }
        onChangeOption={(item) =>
          dropdownChangeHandler(item.value as number, 'year')
        }
      />
      <Select
        options={monthOptions}
        selectedOption={
          typeof monthState === 'number' ? monthOptions[monthState] : undefined
        }
        onChangeOption={(item) =>
          dropdownChangeHandler(item.value as number, 'month')
        }
      />
    </Container>
  );
};

export default YearMonth;
