import React, { useState, useRef, useEffect } from 'react';
import { OptionsType } from 'react-select';
import AsyncSelect, { Props } from 'react-select/async';
import debounce from 'lodash.debounce';
import dropdownStyles from '../Dropdown/Dropdown.style';
import { DropdownOptionType } from '../DropdownTypes';

export interface AsyncDropdownProps extends Props<DropdownOptionType, boolean> {
  oneSelectHandler?: (item: DropdownOptionType) => void;
  multiSelectHandler?: (items: OptionsType<DropdownOptionType>) => void;
  dynamicWidth?: boolean;
  debounceLoadOptions?: (inputText: string) => Promise<DropdownOptionType[]>;
}

const AsyncDropdown: React.FC<AsyncDropdownProps> = ({
  oneSelectHandler,
  multiSelectHandler,
  isMulti,
  debounceLoadOptions,
  styles,
  ...props
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const selectRef = useRef(null);

  useEffect(() => {
    if (typeof props.menuIsOpen !== 'undefined') {
      setMenuIsOpen(props.menuIsOpen);
    }
  }, [props.menuIsOpen]);

  const close = () => {
    setMenuIsOpen(false);
    if (selectRef.current) {
      const ref = selectRef.current as unknown as HTMLSelectElement;
      ref.blur();
    }
  };

  const handleChange = (
    selected: DropdownOptionType | OptionsType<DropdownOptionType> | null
  ) => {
    if (props.closeMenuOnScroll !== false) close();

    if (selected) {
      if (!isMulti && oneSelectHandler) {
        oneSelectHandler(selected as DropdownOptionType);
      } else if (isMulti && multiSelectHandler) {
        multiSelectHandler(
          selected.length ? (selected as OptionsType<DropdownOptionType>) : []
        );
      }
    }
  };

  const loadOptionsHandler = debounce((inputValue, callback) => {
    if (debounceLoadOptions) {
      debounceLoadOptions(inputValue).then((options) => callback(options));
    } else Promise.resolve([]);
  }, 500);

  return (
    <AsyncSelect
      loadOptions={loadOptionsHandler}
      onChange={handleChange}
      isMulti={isMulti}
      menuIsOpen={menuIsOpen}
      onFocus={(e) => {
        setMenuIsOpen(true);
        if (props.onFocus) props.onFocus(e);
      }}
      onBlur={(e) => {
        setMenuIsOpen(false);
        if (props.onBlur) props.onBlur(e);
      }}
      ref={selectRef}
      menuShouldBlockScroll={true}
      styles={{ ...dropdownStyles, ...styles }}
      {...props}
    />
  );
};

export default AsyncDropdown;
