import styled from 'styled-components';
import { devices } from '../../../variables';
import { Button } from '../../../Button';

export const Container = styled.div`
  display: grid;
  width: 50rem;
  @media ${devices.phone} {
    width: 30rem;
  }
`;

export const AddBtn = styled(Button)`
  justify-self: center;
  margin-top: 2rem;
`;
