import { SelectOption } from '../../Select';

export const getYears = (): number[] => {
  const years = [];
  for (let i = new Date().getFullYear(); i >= 1990; i -= 1) {
    years.push(i);
  }
  return years;
};

const MonthsNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const getMonthsOptions = (): SelectOption[] => {
  const monthsOptions: SelectOption[] = [];
  for (let i = 0; i < 12; i += 1) {
    monthsOptions.push({
      label: MonthsNames[i],
      value: i,
    });
  }

  return monthsOptions;
};
