import React from 'react';
import { SingleValueProps, GroupTypeBase } from 'react-select';
import { DropdownOptionType } from '../../../../Dropdown';
import pHeaderIconBaseStyle from '../../../pageHeaderCommonStyle';
import { PHSingleValueContainer } from '../../../common';
import { AppIcon } from './SingleValue.style';

const SingleValue:
  | React.FC<
      SingleValueProps<DropdownOptionType, GroupTypeBase<DropdownOptionType>>
    >
  | undefined = () => {
  return (
    <PHSingleValueContainer>
      <AppIcon style={{ ...pHeaderIconBaseStyle }} />
    </PHSingleValueContainer>
  );
};

export default SingleValue;
