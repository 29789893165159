const sizes = {
  page_header_height: '5.5rem',
  box_vertical_padding: '0.8rem',
  box_horizontal_padding: '1.6rem',
  header_items_gap: '0.5rem',
  page_footer_height: '4rem',
  section_padding: '1rem',
};

export default sizes;
