import styled from 'styled-components';
import { colors } from '../variables';

export default styled.select`
  display: block;
  border-radius: 2px;
  padding: 0.2rem;
  font-size: inherit;

  border: 1px solid ${colors.primaryDarkGray};

  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
    outline: none;
    border: 1px solid ${colors.main};
  }
`;
