import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import colors from '../variables/colors';
import { SPINNER_Z_INDEX } from '../consts';
import Spinner from './Spinner';

const BasePageSpinner = styled(Spinner)`
  margin: 50rem auto;
`;

const Container = styled.div`
  display: grid;
  justify-content: center;
  align-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${rgba(colors.primaryLightGray, 0.2)};
  width: 100%;
  height: 100%;
  z-index: ${SPINNER_Z_INDEX};
`;
interface PageSpinnerProps {
  loading: boolean;
}

const PageSpinner: React.FC<PageSpinnerProps> = ({ loading = false }) => {
  return loading ? (
    <Container>
      <BasePageSpinner />
    </Container>
  ) : null;
};

export default PageSpinner;
