import React from 'react';
import styled from 'styled-components';
import {
  BaseStyle,
  DraftTabTypeStyle,
  IconTypeStyle,
  DraftTabProps,
  ContainerProps,
  RemoveIcon,
  CloseIcon,
  IconWrapper,
} from './DraftTab.style';

const DraftContainer = styled.div<ContainerProps>`
  ${BaseStyle}
  ${DraftTabTypeStyle}
`;

const IconContainer = styled.div<ContainerProps>`
  ${IconTypeStyle}
  width: 32px;
  height: 32px;
`;

const TextContainer = styled.div`
  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 0px 12px;
  line-height: 24px;
`;

type StateType = 'selected' | 'unselected';

export const DraftTab: React.FC<DraftTabProps> = ({
  state,
  firstName,
  lastName,
}: {
  state: StateType;
  firstName: string;
  lastName: string;
}) => {
  const removeWrapper = () => {
    return state === 'selected' ? (
      <IconWrapper>
        <RemoveIcon />
        <IconContainer state={state}></IconContainer>
      </IconWrapper>
    ) : (
      <IconContainer state={state}>
        <RemoveIcon />
      </IconContainer>
    );
  };

  const closeWrapper = () => {
    return state === 'selected' ? (
      <IconWrapper>
        <CloseIcon />
        <IconContainer state={state}></IconContainer>
      </IconWrapper>
    ) : (
      <IconContainer state={state}>
        <CloseIcon />
      </IconContainer>
    );
  };
  return (
    <DraftContainer state={state}>
      <TextContainer>
        {' '}
        {firstName} {lastName}{' '}
      </TextContainer>
      {removeWrapper()}
      {closeWrapper()}
    </DraftContainer>
  );
};

export default DraftTab;
