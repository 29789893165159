import React from 'react';
import styled from 'styled-components';
import { GroupTypeBase, ValueContainerProps } from 'react-select';
import { DropdownOptionType } from '../../DropdownTypes';

export const LocationContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

const ValueContainer:
  | React.FC<
      ValueContainerProps<
        DropdownOptionType,
        boolean,
        GroupTypeBase<DropdownOptionType>
      >
    >
  | undefined = (props) => {
  return (
    // <components.ValueContainer {...props} >
    <LocationContainer>{props.children}</LocationContainer>
    // </components.ValueContainer>
  );
};

export default ValueContainer;
