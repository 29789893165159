import React from 'react';
import styled from 'styled-components';
import Switch, { SwitchProps as SwitchMUIProps } from '@mui/material/Switch';
import {
  getSwitchStyle,
  SwitchText,
  SwitchHint,
  Container,
  TextContainer,
} from './Switch.style';

export interface SwitchProps extends SwitchMUIProps {
  text?: string;
  hint?: string;
}

const SwitchMUI = styled(Switch)`
  ${getSwitchStyle}
`;

const CustomSwitch: React.FC<SwitchProps> = ({
  text,
  hint,
  className,
  disabled,
  ...props
}) => {
  return (
    <Container className={className}>
      <div>
        <SwitchMUI disableRipple={true} disabled={disabled} {...props} />
      </div>
      <TextContainer>
        {text && <SwitchText disabled={disabled}>{text}</SwitchText>}
        {hint && <SwitchHint disabled={disabled}>{hint}</SwitchHint>}
      </TextContainer>
    </Container>
  );
};

export default CustomSwitch;
