import styled, { keyframes } from 'styled-components';
import { ToastT } from './ToastTypes';
import { colors } from '../variables';

const toastInRight = keyframes`
  from {
    transform: translateY(-100%)
  }
  to {
    transform: translateY(0)
  }
`;

const getToastBackgroundColor = (toastType: ToastT) => {
  switch (toastType) {
    case 'success':
      return colors.success700;
    case 'error':
      return colors.secondaryRed;
    case 'warning':
      return colors.warning300;
    default:
      return colors.success700;
  }
};
export const Container = styled.div<{
  headerHeight: string;
  toastType: ToastT;
}>`
  position: fixed;
  top: ${(p) => p.headerHeight};
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  background: ${(p) => getToastBackgroundColor(p.toastType)};
  color: ${colors.white};
  transition: transform 0.3s ease-in-out;
  animation: ${toastInRight} 0.3s;
  z-index: 1000;
  border-radius: 3px;
  display: grid;
  align-items: center;
  gap: 2rem;
  grid-template-columns: max-content 1fr;
  width: fit-content;
`;

export const CloseBtn = styled.button`
  background: none;
  color: ${colors.white};
  border: none;
  cursor: pointer;
`;

export const MessageP = styled.p`
  text-align: center;
`;
