/* eslint-disable import/prefer-default-export */
export const getYearAndMonth = (
  date: number
): { month: number; year: number } => {
  const dateObj = new Date(date);
  return {
    month: dateObj.getUTCMonth(),
    year: dateObj.getUTCFullYear(),
  };
};
