import React, { useCallback, useState } from 'react';
import axios from 'axios';
import { components } from 'react-select';
import { NoticeProps } from 'react-select/src/components/Menu';
import { getCleanQuery } from '../utils';
import { AsyncDropdown, DropdownOptionType } from '../Dropdown';
import { Button } from '../Button';
import { LocationSearch } from '../LocationSearch';
import ENG from '../translation/translationEn.json';
import FR from '../translation/translationFr.json';
import Container from './SelectOtherLocation.style';

interface ApiLocationI {
  id: number;
  name: string;
  [key: string]: any;
}

interface SearchLocationI {
  street: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  full_address: string;
  [key: string]: any;
}

type LocationI = ApiLocationI | SearchLocationI;

export interface SelectOtherLocationProps {
  url: string;
  onSelectLocation: (location: LocationI) => void;
  googleMapsAPIKey: string;
  locationDropdownPlaceholder?: string;
  locationSearchPlaceholder?: string;
  menuPosition?: 'fixed' | 'absolute';
  lang?: 'en' | 'fr'; // for multi languages apps.
}

const SelectOtherLocation: React.FC<SelectOtherLocationProps> = ({
  url,
  onSelectLocation,
  googleMapsAPIKey,
  locationDropdownPlaceholder,
  locationSearchPlaceholder,
  menuPosition = 'fixed',
  lang = 'en',
}) => {
  const [notFound, setNotFound] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const translation = lang === 'fr' ? FR : ENG;

  const loadLocations = useCallback(
    async (query: string) => {
      setNotFound(false);
      const searchUrl = `${url}/${getCleanQuery(query)}`;
      try {
        const { data } = await axios.get(searchUrl);
        const dropdownLocations: ApiLocationI[] = data.locations;

        return dropdownLocations.map((dl) => ({
          label: dl.name,
          value: dl,
        }));
      } catch (err) {
        console.log(err);
        return [];
      }
    },
    [url]
  );

  const NoOptionsMessage = (
    props: NoticeProps<DropdownOptionType, boolean>
  ) => {
    return (
      <components.NoOptionsMessage {...props}>
        <Button
          onClick={() => {
            setIsMenuOpen(false);
            setNotFound(true);
          }}
        >
          {translation.selectOtherLocation.otherLocation}
        </Button>
      </components.NoOptionsMessage>
    );
  };

  return (
    <Container>
      <AsyncDropdown
        debounceLoadOptions={loadLocations}
        defaultOptions={[{ label: '', value: '' }]}
        oneSelectHandler={(item) => {
          if (item.label && item.value) {
            onSelectLocation(item.value);
          }
        }}
        components={{ NoOptionsMessage }}
        onFocus={() => setIsMenuOpen(true)}
        onBlur={() => setIsMenuOpen(false)}
        menuIsOpen={isMenuOpen}
        placeholder={
          locationDropdownPlaceholder ||
          translation.selectOtherLocation.searchPlaceholder
        }
        menuPosition={menuPosition}
      />
      {notFound && (
        <LocationSearch
          placeholder={
            locationSearchPlaceholder ||
            translation.selectOtherLocation.searchPlaceholder
          }
          googleMapsAPIKey={googleMapsAPIKey}
          changeHandler={onSelectLocation}
          style={{ width: '100%', height: '120%' }}
        />
      )}
    </Container>
  );
};

export default SelectOtherLocation;
