import { css } from 'styled-components';

const noScrollBar = () => css`
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export default noScrollBar;
