import React from 'react';
import { LabelProps } from './LabelTypes';
import { StyleLabel, Container } from './Label.style';

const Label: React.FC<LabelProps> = ({ labelType, labelText, className }) => {
  return (
    <>
      {labelType !== 'inDischargeWithBalance' ? (
        <StyleLabel labelType={labelType} className={className}>
          {labelText}
        </StyleLabel>
      ) : (
        <Container className={className}>
          <StyleLabel labelType={'inDischarge'}>{labelText}</StyleLabel>
          <StyleLabel labelType={'dollar'}>{'$'}</StyleLabel>
        </Container>
      )}
    </>
  );
};

export default Label;
