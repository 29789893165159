import React, { ReactNode } from 'react';
import styled from 'styled-components';

const Container = styled.div<{ hasMultipleChildren?: boolean }>`
  display: grid;
  grid-template-columns: ${({ hasMultipleChildren }) =>
    hasMultipleChildren ? '1fr 2fr' : '1fr'};
  gap: 1rem;
  width: 100%;
`;

export interface ConnectedFieldsProps {
  children: ReactNode;
  hasMultipleChildren?: boolean;
}

const ConnectedFields: React.FC<ConnectedFieldsProps> = ({ children }) => {
  return (
    <Container hasMultipleChildren={React.Children.count(children) > 1}>
      {children}
    </Container>
  );
};

export default ConnectedFields;
