import styled from 'styled-components';
import { TypoOverline2 } from '../../../../Typography';
import { colors } from '../../../../variables';

export const PHMenuInitialsSpan = styled(TypoOverline2)`
  display: flex;
  border-radius: 50%;
  border: 1px solid ${colors.white};
  color: ${colors.white};
  align-items: center;
  justify-content: center;
  font-size: 12px;
`;
