/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { UnitOptions } from './consts';
import { Input } from '../Input';
import { Select } from '../Select';
import Container from './Duration.style';

type ValidDurationUnitT = 'years' | 'months' | 'weeks' | 'days';
export type DurationValueT = number | null;
export type DurationUnitT = ValidDurationUnitT | null;
export interface DurationProps {
  value: DurationValueT;
  unit: DurationUnitT;
  excludedUnits?: ValidDurationUnitT[];
  durationChangeHandler: (value: DurationValueT, unit: DurationUnitT) => void;
  className?: string;
}

const Duration: React.FC<DurationProps> = ({
  value,
  unit,
  durationChangeHandler,
  excludedUnits,
  className,
}) => {
  const [valueState, setValueState] = useState<DurationValueT>(null);
  const [unitState, setUnitState] = useState<DurationUnitT>(null);

  useEffect(() => {
    setValueState(value || null);
    setUnitState(unit || null);
  }, [value, unit]);

  const getUnitOption = UnitOptions.find((up) => up.value === unitState);

  const valueChangeHandler = (value: number | null) => {
    durationChangeHandler(value, unitState);
    setValueState(value || null);
  };

  const unitChangeHandler = (unit: ValidDurationUnitT) => {
    durationChangeHandler(valueState, unit);
    setUnitState(unit);
  };

  const getUnitOptions = () =>
    UnitOptions.filter(
      (uo) => !excludedUnits?.includes(uo.value as ValidDurationUnitT)
    );

  return (
    <Container className={className}>
      <Input
        type="number"
        min={1}
        step={1}
        defaultValue={valueState || ''}
        onBlur={(e) => valueChangeHandler(parseFloat(e.target.value) || null)}
      />
      <Select
        options={getUnitOptions()}
        selectedOption={getUnitOption}
        onChangeOption={(item) =>
          unitChangeHandler(item.value as ValidDurationUnitT)
        }
      />
    </Container>
  );
};

export default Duration;
