import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import Badge from '@mui/material/Badge';
import { colors } from '../variables';

const getBadgeStyle = (): FlattenSimpleInterpolation => {
  return css`
    &.MuiBadge-root {
      & > span {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
      }
    }

    &.MuiBadge-root .MuiBadge-colorError {
      background: ${colors.critical700};
      border: 1px solid ${colors.critical300};
    }

    &.MuiBadge-root .MuiBadge-colorInfo {
      background: ${colors.info};
      border: 1px solid ${colors.info300};
    }

    &.MuiBadge-root .MuiBadge-colorSuccess {
      background: ${colors.success};
      border: 1px solid ${colors.success300};
      color: ${colors.main900};
    }

    &.MuiBadge-root .MuiBadge-colorWarning {
      background: ${colors.warning};
      border: 1px solid ${colors.warning700};
      color: ${colors.main900};
    }

    &.MuiBadge-root .MuiBadge-dot {
      border: none;
    }
  `;
};

const BadgeMUI = styled(Badge)`
  ${getBadgeStyle}
`;

export { BadgeMUI as default };
