import React from 'react';
import { ButtonProps } from './ButtonTypes';
import { TypoButton1 } from '../Typography';
import { renderIcons, StyledButton, StyledButtonIcon } from './Button.style';

const Button: React.FC<ButtonProps> = ({
  btnType,
  iconType,
  children,
  ...props
}) => {
  return children ? (
    <StyledButton btnType={btnType} iconType={iconType} {...props}>
      {renderIcons(iconType)}
      <TypoButton1>{children}</TypoButton1>
    </StyledButton>
  ) : (
    <StyledButtonIcon btnType={btnType} iconType={iconType} {...props}>
      {renderIcons(iconType)}
    </StyledButtonIcon>
  );
};

export default Button;
