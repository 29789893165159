import React, { ReactNode, useEffect, useState } from 'react';
import { Container, MessageP, CloseBtn } from './Toast.style';
import { ToastT } from './ToastTypes';

export interface ToastProps {
  headerHeight?: string;
  onClose?: () => void;
  style?: React.CSSProperties;
  className?: string;
  children?: ReactNode;
  message?: string | null;
  toastType?: ToastT;
  closeDelay?: number;
}

const Toast: React.FC<ToastProps> = ({
  message,
  children,
  toastType = 'success',
  onClose,
  headerHeight = '0rem',
  style,
  className,
  closeDelay,
}) => {
  const [showToast, setShowToast] = useState<boolean>(false);

  useEffect(() => {
    setShowToast(!!children || !!message);
  }, [children, message]);

  const closeToastHandler = () => {
    if (onClose) {
      onClose();
    }
    setShowToast(false);
  };

  useEffect(() => {
    if (closeDelay && showToast) {
      setTimeout(() => {
        closeToastHandler();
      }, closeDelay);
    }
  }, [closeDelay, showToast]);

  return showToast ? (
    <Container
      headerHeight={headerHeight}
      toastType={toastType}
      className={className}
      style={style}
    >
      {children || (
        <>
          <CloseBtn onClick={closeToastHandler}>X</CloseBtn>
          <MessageP>{message}</MessageP>
        </>
      )}
    </Container>
  ) : null;
};

export default Toast;
