import React from 'react';
import styled from 'styled-components';

const ToastP = styled.p`
  max-width: 50vw;
`;
const RedirectA = styled.a`
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;
  color: inherit;
`;

export interface ToastWithRedirectProps {
  mainMessage: string;
  redirectMessage: string;
  redirectAddress: string;
  onCloseHandler?: () => void;
}

const ToastWithRedirect: React.FC<ToastWithRedirectProps> = ({
  mainMessage,
  redirectMessage,
  redirectAddress,
  onCloseHandler,
}) => {
  return (
    <ToastP>
      {`${mainMessage} `}
      <RedirectA href={redirectAddress} onClick={onCloseHandler}>
        {redirectMessage}
      </RedirectA>
    </ToastP>
  );
};

export default ToastWithRedirect;
