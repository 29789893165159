import styled from 'styled-components';
import Spinner from '../../Spinner/Spinner';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 50rem;
  font-size: 1.6rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  font-weight: 600;
`;

export const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  align-items: center;
  justify-content: end;
  gap: 1rem;
  width: 100%;
  margin-top: 5rem;
`;

export const SavingSpinner = styled(Spinner)`
  margin-top: 2rem;
`;
