import ReactDatePicker from 'react-datepicker';
import styled from 'styled-components';
import { colors } from '../variables';

/** We should add this line to the entry point of application (index.tsx) `import 'react-datepicker/dist/react-datepicker.css';` */
export default styled(ReactDatePicker)`
  border: 0.1rem solid ${colors.primaryDarkGray};

  &:hover,
  &:focus,
  &:active {
    outline: none;
    border: 0.1rem solid ${colors.main};
  }
`;
