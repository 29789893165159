import React from 'react';
import { Dropdown, DropdownProps, dropdownStyles } from '../../Dropdown';
import { ValueContainer } from '../../Dropdown/ActionDropdown/components';
import { SingleValue } from './components';
import { PHMainMenuOptionsT } from './types';
import { PHMenuStyles } from '../common';
import { constructMainMenuOptions } from './utils';

export interface PHMainMenuProps extends DropdownProps {
  options: PHMainMenuOptionsT;
  menuInitials: string;
}

const PHMainMenu: React.FC<PHMainMenuProps> = ({
  options,
  menuInitials,
  ...props
}) => {
  return (
    <Dropdown
      styles={{ ...dropdownStyles, ...PHMenuStyles, ...props.styles }}
      options={constructMainMenuOptions(options)}
      oneSelectHandler={(option) =>
        option.url
          ? window.open(option.url)
          : !!option.onClick && option.onClick()
      }
      components={{
        ...props.components,
        SingleValue,
        ValueContainer,
        DropdownIndicator: () => null,
      }}
      minMenuWidth="max-content"
      singleValueProps={menuInitials}
      value={{ label: '', value: '' }}
      isSearchable={false}
      dynamicMenuWidth={true}
      {...props}
    />
  );
};

export default PHMainMenu;
