import styled from 'styled-components';
import { MdCancel } from 'react-icons/md';
import { colors } from '../variables';

const DeleteIcon = styled(MdCancel)`
  cursor: pointer;
  &:hover {
    color: ${colors.secondaryRed};
  }
`;

export default DeleteIcon;
